/* Navigation bar Start */
.tc-navbar-content
  .tc-navbar-profile-dropdown
  .dropdown-menu
  .dropdown-item:hover {
  background: #00000010;
}

.tc-navbar-content
  .tc-navbar-profile-dropdown
  .dropdown-menu
  .dropdown-item.active {
  background: #a6e1df;
}

.tc-navbar-content
  .tc-navbar-profile-dropdown
  .dropdown-menu
  .dropdown-item.tc-navbar-profile-logout-item {
  color: #f00000;
}

.tc-navbar-content
  .tc-navbar-profile-dropdown
  .dropdown-menu
  .tc-notification-count {
  padding: 0px 5px;
  font-size: 13px;
  margin-left: 2px;
  border-radius: 10px;
  background-color: #115b48;
  font-weight: 700;
  color: #ffffff;
}

.tc-navbar-user-profile {
  position: relative;
  margin-right: 10px;
}

.tc-profile-notification-count {
  background-color: #115b48;
  position: absolute;
  z-index: 99999;
  right: -14px;
  top: -10px;
  border-radius: 10px;
  padding: 0px 6px;
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: fit-content;
}

.tc-navbar-content
  .tc-navbar-profile-dropdown
  .dropdown-menu
  .dropdown-item
  img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.logout-btn img {
  width: 25px !important;
  height: 25px !important;
  margin-right: 6px !important;
}

.tc-navbar-back svg {
  width: 20px;
  height: 20px;
}

.tc-navbar-back-show .tc-navbar-back {
  display: block;
}

/* Offcanvas CSS Start */
.offcanvas-backdrop {
  z-index: 0;
}

.offcanvas-backdrop.show {
  opacity: 0 !important;
}

.offcanvas {
  background: #a6e1df;
  max-width: 500px;
  margin: 0 auto;
  right: 0;
  top: 64px;
  width: 100%;
  height: fit-content;
  left: 0;
  border: 0;
  z-index: 2;
}

.offcanvas.offcanvas-start {
  top: 64px;
  width: 100%;
  transform: translateY(-100%) !important;
  border: 0;
}

.offcanvas.show:not(.hiding),
.offcanvas.showing {
  transform: none !important;
}

.navbar {
  padding: 0;
}

/* Modal CSS Start */
.tc-model-dialog .modal-dialog {
  max-width: 400px;
  margin: 0 auto;
}

.tc-modal-btn {
  padding: 0;
  margin: 0;
  border: 0;
}

.tc-model-header,
.tc-model-body {
  padding: 10px 15px;
}

.tc-model-body {
  padding-top: 15px;
}

.tc-model-header .btn-close {
  padding: 0;
  margin: 0;
}

.tc-model-header .btn-close:focus {
  box-shadow: none;
}

.tc-model-header .tc-model-header-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  color: #000;
  margin-bottom: 0;
}

.tc-model-body .tc-model-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2;
  color: #6ebdbc;
  margin-bottom: 25px;
  text-align: center;
}

.tc-model-body .tc-model-text {
  margin-bottom: 30px;
}

.tc-model-body .tc-model-text p {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  color: #000;
  margin-bottom: 5px;
  text-align: center;
}

/* Login Section Start */
.tc-login-content .tc-logo {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2;
  color: #6697a1;
  margin-bottom: 40px;
  text-align: center;
  font-family: "Biome";
}

.tc-login-content .tc-number-input {
  background: #fdfdfd;
  border: 1px solid #a6e1df;
  border-radius: 10px;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #7f7f7f;
  padding: 9px 10px;
  margin-bottom: 20px;
}

.tc-login-content .tc-number-input input {
  border: 0;
}

.tc-login-form .tc-login-form-box {
  margin: auto 0;
}

.tc-login-form .tc-login-top-space {
  height: 30px;
  margin-bottom: auto;
}

.tc-login-form .tc-login-form-item {
  margin-bottom: 50px;
}

.tc-login-form .tc-login-form-list {
  margin-bottom: 15px;
}

.tc-login-form .tc-login-form-list .tc-login-form-list-input-row {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  align-items: center;
}

.tc-login-form .tc-login-form-list .tc-login-option {
  border: 2px solid #4d5f4e;
  padding: 5px 10px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

.tc-login-form .tc-login-form-list label {
  margin-right: 15px;
}

.tc-login-form .tc-login-form-list {
  margin-bottom: 15px;
}

.tc-login-form .tc-logo {
  font-size: 30px;
  display: block;
  margin-bottom: 20px;
}

.tc-login-form .tc-logo img {
  width: 100%;
  height: 30vh;
  object-fit: contain;
}

/* .tc-login-form .tc-logo2 img {
  width: 70%;
} */

.tc-footer .tc-footer-content .tc-footer-tab img {
  width: 24px;
  height: 24px;
  margin-bottom: 5px;
}

.tc-footer-section .tc-footer-content .tc-footer-tab.active {
  color: #222124;
}

.tc-footer-section .tc-footer-link-disabled {
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.tc-footer-section .tc-footer-link-disabled svg {
  color: grey;
}

/* Product Sidebar Section Start*/
.tc-navbar-back-text .tc-navbar-right-btn {
  width: 25px;
  height: 25px;
  margin-left: auto;
}

.tc-navbar-right-side-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 35px;
  margin-left: auto;
  border-radius: 4px;
}

.tc-navbar-back-text .logout-btn {
  background-color: #ffffff;
  color: var(--bs-red);
  font-weight: 700;
}

.tc-sidebar-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.2;
  color: #ffffff;
  font-family: "Khula", sans-serif;
  margin: 0 auto -5px;
}

.tc-quicksign-content .tc-quicksign-total {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.tc-quicksign-list-section .tc-quicksign-list-box {
  margin-top: 20px;
}

.tc-quicksign-back-btn {
  left: 0;
  cursor: pointer;
}

.tc-quicksign-back-btn svg {
  width: 20px;
}

.tc-quicksign-list-section .tc-quicksign-list-box:nth-child(1),
.tc-quicksign-list-section .tc-quicksign-list-box:nth-child(2),
.tc-quicksign-list-section .tc-quicksign-list-box:nth-child(3) {
  margin-top: 0px;
}

.tc-quicksign-list-section .tc-quicksign-list-box p {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin-bottom: 10px;
}

.tc-quicksign-content .tc-quicksign-total .tc-quicksign-number {
  font-size: 18px;
  font-weight: 700;
  color: #000000;
  text-align: center;
  margin-bottom: 0;
}

.tc-quicksign-content .tc-quicksign-total .tc-quicksign-icon {
  color: #008000;
  width: 24px;
  margin-left: 5px;
  display: inline-block;
}

.tc-quicksign-content .tc-quicksign-date-time-text {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 4px;
}

.tc-quicksign-content .tc-quicksign-signature-box {
  position: relative;
}

.tc-quicksign-content
  .tc-quicksign-signature-box
  .tc-quicksign-signature-erase-btn {
  background: #ffffff;
  border: 1px solid #528e97;
  color: #528e97;
  padding: 8px 15px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  right: 10px;
  top: 10px;
  transition: all 0.3s ease-in-out;
}

.tc-quicksign-content
  .tc-quicksign-signature-box
  .tc-quicksign-signature-zoom-in {
  display: block;
  width: -moz-fit-content;
  position: absolute;
  right: 10px;
  top: 10px;
}

.tc-quicksign-content
  .tc-quicksign-signature-box
  .tc-quicksign-signature-zoom-in
  svg {
  width: 30px;
  height: 30px;
}

.tc-quicksign-content
  .tc-quicksign-signature-box
  .tc-quicksign-signature-erase-btn:hover {
  background: #ffeeee;
  border-color: var(--bs-red);
  color: var(--bs-red);
}

.tc-quicksign-content .tc-quicksign-signature-box .tc-quicksign-signaturepad {
  width: 100%;
  height: 350px;
  border: 2px solid var(--bs-red);
  padding: 0 40px 0 0;
}

.tc-quicksign-modal .tc-save {
  margin-top: 30px;
}

.tc-matchsign-modal {
  z-index: 1050 !important;
}

.tc_add_link_btn {
  font-size: 12px;
  text-decoration: underline;
  color: #32b0f0;
  font-weight: 500;
  cursor: pointer;
}

.tc-quicksign-radio .form-check-input:checked {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  accent-color: var(--bs-primary);
}

.form-check-input:active {
  filter: brightness(100%);
}

/* QuickSign Section End */

/* Out Of Field Section */
.tc-field-content .tc-table tr:first-child th {
  border: 0;
}

.tc-calender-search-input-row
  .tc-calender-input-content
  input[type="date"]::-webkit-calendar-picker-indicator {
  display: block;
  background: url("../images/dropDownIcon.svg") no-repeat;
  width: 12px;
  height: 12px;
}

.tc-calender-search-input-row .tc-input-content-icon svg {
  width: 36px;
  height: 38px;
  margin-right: 10px;
  cursor: pointer;
}

.month-static-disabled {
  width: 100%;
  border-radius: 6px;
  color: #ffffff;
  font-weight: 700;
  padding: 5px 8px;
  font-size: 16px;
  background-color: #a6a6a6;
  max-width: 140px;
}

.tc-table-body-border-bottom {
  border-bottom: 2px solid var(--bs-red);
}

.tc-md-list-common-detail .tc-md-list-common-detail-row:last-child {
  margin-bottom: 0;
}

.tc-md-list-common-detail
  .tc-md-list-common-detail-row.tc-md-list-call-objective-text {
  align-items: flex-start;
}

.tc-md-list-common-detail
  .tc-md-list-common-detail-row.tc-md-list-call-objective-text
  .tc-md-list-common-detail-text {
  border: 1px solid #9cdedd;
  border-radius: 14px;
  min-height: 80px;
  max-height: 80px;
  overflow-y: auto;
}

.tc-md-list-common-detail .promo-mats-title-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tc-md-list-common-detail .promo-mats-title-content .promo-mats-first-content {
  width: auto;
  min-width: 200px;
}

.tc-md-list-common-detail .promo-mats-title-content .promo-mats-title {
  width: auto;
  font-size: 20px;
  font-weight: 700;
}

.tc-md-list-common-detail .promo-mats-title-content .filter-input-promo-mats {
  width: auto;
  min-width: 220px;
  border: 2px solid var(--bs-primary);
  padding: 8px;
  border-radius: 6px;
}

.tc-call-activity-table .color-gray-table td {
  background-color: #c7c7c7 !important;
  pointer-events: none;
}

.tc-call-activity-table .color-gray-table td input {
  background-color: #c7c7c7 !important;
}

.tc-md-list-common-detail
  .tc-md-list-common-detail-row
  .tc-md-list-common-detail-name {
  font-size: 18px;
  font-weight: 800;
  color: var(--bs-primary);
  display: flex;
}

.tc-table tr td .tc-activity-check-btn {
  width: 20px;
  height: 20px;
  border: 2px solid var(--bs-red);
  background: #ffffff;
  border-radius: 3px;
  position: relative;
}

.tc-table tr td .tc-activity-check-btn::before {
  content: "";
  position: absolute;
  left: 4px;
  top: 0px;
  width: 7px;
  height: 12px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  z-index: 9;
  opacity: 0;
}

.tc-table tr td .tc-activity-check-btn.active {
  background: var(--bs-red);
  border-color: var(--bs-red);
}

.tc-table tr td .tc-activity-check-btn.active::before {
  opacity: 1;
}

.margin-top-next-call {
  margin-top: 120px;
}

.tc-md-list-detail-bottom-btn-content .tc-md-list-detail-bottom-btn {
  position: relative;
  padding: 10px 20px;
  background: var(--bs-primary);
  border: 1px solid var(--bs-primary);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0 auto;
  width: fit-content;
  border-radius: 10px;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-transform: uppercase;
}

.tc-call-activity-table .tc-md-list-table-activity-tr {
  border-bottom: 2px solid var(--bs-red);
}

.tc-table tr td.table-actual-qty-value {
  padding: 12px 12px 12px 4px !important;
  white-space: nowrap;
}

.error-border-right-side {
  border-left: 8px solid var(--bs-red) !important;
}

.tc-call-activity-table-tr {
  border-bottom-style: none;
}

.tc-table tr td .tc-md-list-table-td-error,
.tc-error {
  color: red;
  font-size: 13px;
  font-weight: 600;
  display: block;
  text-align: left;
  margin-top: 5px;
}

.tc-table tr td .tc-md-list-table-paragraph:nth-child(1) {
  margin-top: 0px;
}

.tc-table .tc-md-list-table-product-detail-row td:nth-child(1) {
  padding: 10px 20px;
}

.tc-table .tc-md-list-table-product-detail-row td p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.tc-table .tc-md-list-table-product-sub-detail-box > td {
  border: 0;
  padding: 0;
}

.tc-table
  .tc-md-list-table-product-sub-detail-box
  .tc-md-list-table-product-detail-row {
  display: flex;
  align-items: center;
  width: 100%;
}

.tc-table
  .tc-md-list-table-product-sub-detail-box
  .tc-md-list-table-product-detail-row:last-child {
  border-bottom: 1px solid var(--bs-red);
}

.tc-table
  .tc-md-list-table-product-sub-detail-box
  .tc-md-list-table-product-detail-row
  td {
  padding-top: 5px;
  padding-bottom: 0;
}

.tc-table
  .tc-md-list-table-product-sub-detail-box
  .tc-md-list-table-product-detail-row
  td:nth-child(1) {
  padding-left: 40px;
  max-width: 60%;
  width: 100%;
}

.tc-table
  .tc-md-list-table-product-sub-detail-box
  .tc-md-list-table-product-detail-row
  td:last-child {
  max-width: 40%;
  width: 100%;
  text-align: left;
}

.tc-table
  .tc-md-list-table-product-sub-detail-box
  .tc-md-list-table-product-detail-row:nth-child(1)
  td {
  padding-top: 10px;
}

.tc-table
  .tc-md-list-table-product-sub-detail-box
  .tc-md-list-table-product-detail-row:last-child
  td {
  padding-bottom: 10px;
}

.tc-table .tc-md-list-table-product-detail-row td:nth-child(1) p {
  text-align: left;
}

.tc-table tr td .tc-md-list-table-pdf-btn img {
  width: 75px;
  height: auto;
}

.tc-table tr td .tc-md-list-table-question-answer-input {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  color: #000;
  padding: 6px;
  max-width: 150px;
  width: 100%;
  border: 2px solid var(--bs-red);
  border-radius: 8px;
}

.tc-table tr td .tc-md-list-table-lot-input {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  color: #000;
  padding: 6px;
  width: 100%;
  border: 2px solid var(--bs-red);
  border-radius: 8px;
}

.tc-table tr td .tc-md-list-table-question-answer-select {
  background-image: url("../images/BottomFillArrow.svg");
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: calc(100% - 10px) center;
  padding-right: 35px;
}

.tc-table tr td .tc-md-list-table-question-answer-select,
.tc-table tr td .tc-md-list-table-question-answer-textarea {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  color: #000000;
  padding: 6px;
  max-width: 100%;
  width: 100%;
  border: 2px solid var(--bs-red);
  border-radius: 8px;
  -webkit-appearance: none;
}

.tc-table tr td .tc-md-list-table-question-answer-textarea {
  height: 80px;
  resize: none;
}

.tc-border-bottom-table tbody tr td {
  width: 50%;
}

.tc-join-call-table tr td {
  vertical-align: top;
}

.tc-join-call-table tr td:nth-child(1) {
  padding-top: 18px;
}

.tc-signaturepad-time-text {
  font-size: 14px;
  font-weight: 700;
  color: #000000;
  text-align: center;
}

.tc-signpad-time {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
}

.tc-md-list-behavior-input-box .tc-call-summary-signature-box {
  position: relative;
}

.tc-md-list-behavior-input-box
  .tc-call-summary-signature-box.tc-joint-call-signature-box
  .tc-join-call-digi-signaturepad {
  height: 300px;
  border: 2px solid var(--bs-red);
}

.tc-md-list-behavior-input-box
  .tc-call-summary-signature-box
  .tc-call-summary-signature-erase-btn {
  background: #ffffff;
  border: 2px solid var(--bs-red);
  color: var(--bs-red);
  padding: 8px 15px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 700;
  display: block;
  width: fit-content;
  position: absolute;
  right: 10px;
  top: 10px;
  transition: all 0.3s ease-in-out;
}

.tc-md-list-behavior-input-box
  .tc-call-summary-signature-box
  .tc-call-summary-signature-erase-btn:hover {
  background: #ffeeee;
  border-color: var(--bs-red);
  color: var(--bs-red);
}

.tc-md-list-behavior-input-box .tc-md-list-behavior-input-tectarea-box {
  margin-top: 10px;
}

.tc-md-list-behavior-input-box
  .tc-md-list-behavior-input-tectarea-box:first-child {
  margin-top: 0;
}

.tc-md-list-behavior-input-box
  .tc-md-list-behavior-input-tectarea-box
  .tc-md-list-behavior-input {
  height: 100px;
}

.tc-md-list-behavior-input-box .tc-md-list-behavior-input-label {
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 4px;
}

.tc-md-list-behavior-input-box .tc-md-list-behavior-input {
  width: 100%;
  height: 150px;
  border: 2px solid var(--bs-red);
  border-radius: 14px;
  resize: none;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.tc-dashboard-table-box {
  background: #ffffff;
  padding: 25px 10px 10px;
}

.tc-dashboard-table-box .tc-dashboard-table-box-heading {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 35px;
}

.tc-dashboard-table-box
  .tc-dashboard-table-box-heading
  .tc-dashboard-table-box-heading-text {
  font-size: 24px;
  color: #000000;
  font-weight: 800;
  margin-bottom: 0;
}

.tc-dashboard-table-box
  .tc-dashboard-table-box-heading
  .tc-dashboard-table-box-heading-text
  span {
  font-size: 24px;
  font-weight: 600;
}

.tc-dashboard-table-box
  .tc-dashboard-table-box-heading
  .tc-dashboard-table-box-heading-text
  svg {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.tc-dashboard-table-box
  .tc-dashboard-table-box-heading
  .tc-dashboard-table-box-heading-date {
  margin-left: auto;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  color: #000000;
}

.tc-dashboard-report-col {
  background: #e9f1f1;
  border-radius: 16px;
}

.tc-dashboard-report-col .tc-dashboard-report-col-head {
  padding: 15px 20px;
}

.tc-dashboard-report-col .tc-dashboard-report-col-head h3 {
  color: var(--bs-primary);
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 0;
}

.tc-dashboard-report-col .tc-dashboard-report-col-list {
  max-height: 247px;
  overflow-y: auto;
}

.tc-dashboard-report-col .tc-dashboard-report-col-list p {
  font-size: 17px;
  font-weight: 600;
  color: #3a3633;
  margin-bottom: 0;
  padding: 8px 20px;
}

.tc-dashboard-report-col .tc-dashboard-report-col-list p:nth-child(2n + 1) {
  background: #dde5e5;
}

.tc-dashboard-body .dashboard-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  flex-grow: 1;
}

.tc-social-platform-btn {
  color: #249493;
}

.tc-call-summary-photo-upload-box .tc-call-summary-photo-upload-box-text {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 9;
}

.tc-call-summary-photo-upload-box .tc-call-summary-photo-upload-box-text span {
  font-size: 22px;
  line-height: 1.1;
  font-weight: 600;
  color: #000000;
  max-width: 165px;
  display: block;
  text-align: center;
}

.tc-quicksign-date-camera-row {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.tc-quicksign-content .tc-quicksign-date-camera-row {
  margin-top: auto;
}

.tc-quicksign-content .tc-md-list-detail-bottom-btn-content {
  margin-bottom: auto;
}

.tc-quicksign-content .tc-quicksign-total {
  margin-bottom: auto;
}

.tc-quicksign-date-camera-row .tc-quicksign-date-time-text {
  margin-bottom: 0;
}

.tc-md-list-behavior-input-box
  .tc-call-summary-signature-box.tc-call-summary-photo-upload-box
  .tc-call-summary-signature-erase-btn {
  position: relative;
  z-index: 99;
}

.tc-quicksign-signature-box.tc-call-summary-photo-upload-box {
  min-height: 340px;
}

.tc-quicksign-signature-box.tc-call-summary-photo-upload-box
  .tc-quicksign-signature-erase-btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
  margin-top: 0;
}

.tc-modal-click-photo {
  width: 100%;
  height: auto;
  margin-bottom: 8px;
}

.tc-selfie-sign-modal-click-photo {
  width: 100%;
  height: auto;
  margin-bottom: 8px;
  flex: 1;
  max-height: calc(100vh - 275px);
}

.zoom-in {
  transform: scale(1.5);
  transition: transform 0.3s ease-in-out;
  transform-origin: center center;
}

.zoom-out {
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
  transform-origin: center center;
}

.tc-webcam-zoominout-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 75%;
  text-align: center;
}

.tc-webcam-zoominout-wrap button {
  margin: auto;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffc000;
}

.tc-webcam-zoominout-btn svg {
  width: 25px;
  height: 25px;
}

.tc-call-summary-photo-upload-box
  .tc-call-summary-photo-upload-img.tc-quicksign-click-photo-img {
  width: 100%;
  position: relative;
  height: auto;
  max-height: 450px;
}

.tc-md-list-behavior-input-box
  .tc-call-summary-signature-box.tc-call-summary-photo-upload-img-box
  .tc-call-summary-signature-erase-btn {
  position: absolute;
}

.tc-custom-select-sm {
  width: 150px;
}

.tc-custom-select-mx {
  width: 100%;
}

.tc-custom-select-sm {
  width: 150px !important;
}

.tc-custom-select-3 .select__control {
  border: 2px solid #84d344 !important;
  border-radius: 6px;
  box-shadow: none;
  background-color: #ffffff;
  box-shadow: none !important;
  width: 150px;
}

.tc-custom-select-3-error .select__control {
  border-left: 8px solid #84d344 !important;
  border: 2px solid #84d344 !important;
  border-radius: 6px;
  box-shadow: none !important;
}

.tc-custom-select-3 .select__control .select__placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #84d344;
}

.tc-custom-select-3 .select__control .select__input-container .select__input {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #84d344 !important;
  border: 0 !important;
}

.tc-custom-select-3
  .select__control
  .select__indicators
  .select__indicator-separator {
  display: none;
}

.tc-custom-select-3
  .select__control
  .select__indicators
  .select__dropdown-indicator {
  height: 36px;
  position: relative;
  width: 36px;
}

.tc-custom-select-3
  .select__control
  .select__indicators
  .select__dropdown-indicator:before {
  border: 10px solid transparent;
  border-top: 12px solid #84d344;
  content: "";
  left: 50%;
  position: absolute;
  top: 12px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.tc-custom-select-3 .select__single-value {
  color: #84d344;
  font-weight: 700;
}

.tc-custom-select-3 .select__option--is-selected {
  color: #84d344;
  background-color: #ffffff;
  font-weight: 700;
}

.tc-custom-select-3 .select__option--is-focused {
  color: #111111;
  background-color: #e3ffcd;
}

/* custom select css border green color end */

.tc-out-of-field-md-modal-checkbox-list .form-check:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.tc-out-of-field-md-modal-checkbox-list
  .form-check
  .form-check-input:checked
  ~ .form-check-label::after {
  opacity: 1;
}

.tc-time-in-out-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time-in-time-out-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}

.time-container-options {
  padding: 4px 27px 0px 19px;
}

.disable-time-options {
  background: #b0b0b0;
  pointer-events: none;
}

.modal-title-p {
  font-size: 18px !important;
  font-weight: 700 !important;
  color: #000 !important;
  margin-bottom: 0;
  text-align: center;
}

.modal-title-h4 {
  font-size: 22px !important;
  font-weight: 500 !important;
}

.navbar-time-class {
  height: 27px;
  width: 28px;
  margin-right: -1px;
  margin-left: -4px;
  color: var(--bs-red);
}

.time-in-btn {
  position: relative;
  padding: 13px 18px;
  background-color: var(--bs-primary);
  border: 1px solid var(--bs-primary);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0 auto;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10px;
  color: #fff;
  margin: 10px;
}

.time-out-btn {
  position: relative;
  padding: 13px 12px;
  background-color: var(--bs-red);
  border: 1px solid var(--bs-red);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0 auto;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10px;
  display: flex;
  color: #fff;
  margin: 10px;
}

.time-in-out-btn-disable {
  background-color: #a8a4a4;
  border: 1px solid #a8a4a4;
  pointer-events: none;
}

.time-in-out-blank-footer {
  padding: 0 30px;
  background-color: var(--bs-primary);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  z-index: 1;
  max-width: 1024px;
}

.time-options {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.cm-select .css-13cymwt-control,
.cm-select .css-t3ipsp-control:hover {
  border: 1px solid red !important;
}

.css-1jqq78o-placeholder {
  text-align: left;
}

/* itinary css start */
.tc-card-itinerary-content {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin-top: 10px;
  gap: 20px;
}

.tc-card-itinerary-container .tc-card-input-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: baseline;
}

.tc-card-itinerary-container .tc-card-input-container .tc-card-input-label {
  font-size: 16px;
  font-weight: 800;
  width: 330px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.tc-card-itinerary-container .tc-card-input-container .tc-card-static-input {
  width: 100%;
  border-radius: 2px;
  margin-bottom: 8px;
  color: #333;
  font-weight: 700;
  padding: 5px 8px;
  font-size: 14px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  margin-left: 3px;
}

.tc-card-itinerary-container .tc-card-input-container .color-gray {
  background-color: #a6a6a6;
}

.tc-card-itinerary-container .tc-card-input-container .color-red {
  background-color: var(--bs-red);
}

.tc-card-itinerary-container .tc-card-input-container .color-pink {
  background-color: #ff99cc;
}

.tc-card-itinerary-container .tc-card-input-container .color-blue {
  background-color: #00b0f0 !important;
}

.tc-card-input-weekday {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  max-height: 150px;
  overflow-y: auto;
}

.tc-card-input-weekday .tc-card-weekday-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(50% - 5px);
  border-radius: 6px;
  margin-bottom: 10px;
  color: #ffffff;
  font-weight: 700;
  padding: 8px;
  font-size: 16px;
}

.tc-card-input-weekday .tc-card-weekday-input svg {
  height: 22px;
  width: 22px;
}

.tc-edit-itinerary-wrap {
  width: 100%;
  /* margin-top: 1.5rem; */
  /* padding-top: 1.5rem; */
  /* border-top: 2px solid var(--bs-gray-100); */
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  border-left: 1px solid #ccc;
}

.tc-edit-itinerary-wrap .tc-edit-itinerary-reason,
.tc-edit-itinerary-attachment {
  display: flex;
  flex-direction: column;
}

.tc-edit-itinerary-wrap .tc-edit-itinerary-reason .form-control {
  border: 3px solid var(--bs-primary);
  border-radius: 20px;
}

.tc-edit-itinerary-attachment .tc-file-upload-input {
  min-height: 100px !important;
}

.tc-edit-itinerary-attachment .tc-file-upload-input input {
  bottom: 0;
}

/* .tc-edit-itinerary-attachment .tc-file-upload-input .tc-file-upload-label {
  min-height: 100px !important;
} */

.tc-main-modal .modal-detail-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tc-main-modal .modal-detail-content .modal-detail-main-text {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 4px;
  color: var(--bs-red);
}

.tc-main-modal .modal-detail-content .modal-detail-sub-text {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}

.tc-main-modal .modal-btn-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tc-main-modal .modal-btn-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.tc-main-modal .modal-btn-row .modal-cancel-btn {
  background: gray;
  border: none;
  padding: 8px;
  font-size: 20px;
  margin-right: 10px;
  min-width: 100px;
  width: auto;
}

.tc-main-modal .modal-btn-row .modal-confirm-btn {
  background: #84d344;
  border: none;
  padding: 8px;
  font-size: 20px;
  margin-left: 10px;
  min-width: 100px;
  width: auto;
}

.modal-detail-content .tc-modal-input-container {
  display: flex;
  width: 100%;
  align-items: center;
}

.modal-detail-content .tc-modal-input-container .tc-modal-input-label {
  width: auto;
  min-width: 100px;
  font-size: 17px;
  font-weight: 800;
  margin-bottom: 4px;
}

.modal-detail-content .tc-modal-input-container .tc-modal-input-field {
  width: 100%;
  border: 2px solid #84d344;
  border-radius: 6px;
  padding: 2px 10px;
}

/* promomats modal css start */
.promo-mats-modal-checkbox-list-box .promo-mats-modal-checkbox-list-box-count {
  text-align: right;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-bottom: 3px;
}

/* promomats modal css end */

/* sidebar report css start */
.tc-charts-content {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  overflow-x: auto;
}

.tc-charts-content .tc-charts-two-pie-chart {
  display: flex;
  gap: 4px;
}

.tc-report-table {
  width: 100%;
  overflow-x: auto;
  max-height: calc(100vh - 640px);
  border: 3px solid var(--bs-red);
  border-radius: 20px;
}

.tc-report-table table {
  width: 100%;
  min-width: 580px;
}

.tc-report-table table thead {
  position: sticky;
  top: 0;
}

.tc-report-table .tc-report-table-head {
  background: var(--bs-red);
}

.tc-report-table .tc-report-table-head th {
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  padding: 10px 6px;
}

.tc-report-table .tc-report-table-sec-head {
  position: sticky;

  background: #ffffff;
}

.tc-report-table .tc-report-table-sec-head td {
  color: #000000;
  font-weight: 800;
  font-size: 14px;
  text-align: center;
  padding: 10px 6px;
}

.tc-report-table tbody tr:nth-child(2n + 1) {
  background: #f2f2f2;
}

.tc-report-table tbody tr {
  border-bottom: 1px solid #f2f2f2;
}

.tc-report-table tbody tr:first-child {
  border-bottom: none;
}

.tc-report-table tbody .tc-report-table-body-text td {
  color: #000000;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  padding: 10px 6px;
}

.tc-report-table tbody .tc-report-table-body-text td:nth-child(1) {
  text-transform: uppercase;
}

.tc-report-table tfoot {
  position: sticky;
  bottom: 0;
  background: #ffffff;
}

.tc-report-table tfoot .tc-report-table-footer td {
  color: #4c4cfb;
  font-weight: 800;
  font-size: 18px;
  text-align: center;
  padding: 10px 6px;
  text-transform: uppercase;
  background: #f2f2f2;
}

.tc-report-table tfoot .green-color td {
  color: var(--bs-primary);
}

/* sidebar report css end */

/* tc sign modal start */
.tc-sign-modal {
  width: 100%;
  margin: 0 auto;
}

.tc-modal-quicksign-signature-box .tc-modal-quicksign-signaturepad {
  width: 100%;
  height: calc(100vh - 210px);
}

.tc-sign-modal .modal-content {
  background: #ffffff !important;
}
.tc-sign-selfie-modal .modal-content {
  border: 4px solid #8b0018;
  border-radius: 80px;
}

.tc-sign-selfie-modal .modal-dialog {
  min-width: 90%;
}

/* tc sign modal end */
.tc-monthly-md-btn-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tc-monthly-md-btn-container {
  margin: 10px;
}

.tc-monthly-md-btn {
  position: relative;
  padding: 10px 20px;
  background: var(--bs-primary);
  border: 1px solid var(--bs-primary);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0 auto;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10px;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-transform: uppercase;
}

button.tc-monthly-md-btn:disabled,
button.tc-monthly-md-btn.disabled {
  background-color: #a6a6a6;
  border-color: #a6a6a6;
  opacity: 1;
}

/* sign selfie modal css start */
.sign-selfie-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-bottom: 50px;
}

.sign-selfie-modal-header .sign-selfie-modal-header-text {
  font-size: 36px;
  font-weight: 700;
}

.sign-selfie-modal-header .sign-selfie-modal-header-close-btn img {
  width: 40px;
  height: 40px;
}

.sign-selfie-modal-current-time {
  font-size: 16px;
  font-weight: 700;
}

.sign-selfie-quicksign-signaturepad {
  width: 100%;
  height: calc(100vh - 275px);
}

.selfie-modal-body {
  display: flex;
  flex-direction: column;
}

.sign-selfie-modal-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.sign-selfie-modal-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  height: auto;
}

.sign-selfie-modal-preview img {
  max-height: calc(100vh - 275px);
}

.tc-login-form .tc-logo img {
  width: 100%;
  height: 30vh;
  object-fit: contain;
}

.tc-login-form .tc-logo img {
  width: 50%;
  height: 20vh;
  object-fit: contain;
}

.tc-login-form .tc-logo2 img {
  width: 100%;
  height: 30vh;
  object-fit: contain;
}

@media (max-width: 675px) {
  .tc-data-table .rdt_Table .rdt_TableBody .tc-data-table-md-name-text {
    font-size: 16px;
  }
}

@media (max-width: 575px) {
  .tc-dashboard-table-box
    .tc-dashboard-table-box-heading
    .tc-dashboard-table-box-heading-text {
    font-size: 18px;
  }

  .tc-dashboard-table-box
    .tc-dashboard-table-box-heading
    .tc-dashboard-table-box-heading-date,
  .tc-dashboard-table-box
    .tc-dashboard-table-box-heading
    .tc-dashboard-table-box-heading-text
    span {
    font-size: 14px;
  }

  .tc-modal-quicksign-signature-box .tc-modal-quicksign-signaturepad {
    width: 100%;
    height: calc(100vh - 100px);
  }
}

@media (max-width: 500px) {
  .tc-calender-search-input-row {
    display: block;
  }

  .tc-calender-search-input-row .tc-calender-input-content,
  .tc-calender-search-input-row .tc-search-input-content {
    max-width: 100%;
  }

  .tc-calender-search-input-row .tc-search-input-content {
    margin-left: 0;
    margin-top: 10px;
  }

  .tc-body.tc-sec-body-section,
  .tc-sidebar-body {
    padding: 26px 24px;
  }

  .tc-dashboard-table-box .tc-dashboard-table-box-heading {
    display: block;
  }

  .tc-dashboard-table-box
    .tc-dashboard-table-box-heading
    .tc-dashboard-table-box-heading-date {
    margin-top: 10px;
  }
}

@media (max-width: 475px) {
  .tc-footer {
    padding: 0 20px;
  }

  .tc-footer .tc-footer-content .tc-footer-tab {
    font-size: 10px;
  }

  .tc-md-list-common-detail
    .tc-md-list-common-detail-row
    .tc-md-list-common-detail-label {
    max-width: 90px;
  }
}

/* Yash new css end */

@media screen and (max-width: 425px) {
  .tc-navbar {
    padding: 8px;
  }

  .tc-footer {
    padding: 0 20px;
  }

  .tc-footer .tc-footer-content .tc-footer-tab svg {
    width: 20px;
    height: 20px;
  }

  .tc-login-content .tc-logo {
    margin-bottom: 30px;
  }

  /* Home page */
  .tc-body {
    padding: 12px;
    margin-top: 56px;
    max-height: calc(100vh - 114px);
  }

  .tc-card-content .tc-card-qr .tc-card-qr-text {
    font-size: 26px;
  }

  .tc-card-content .tc-card-text {
    font-size: 14px;
  }

  .tc-card-content .tc-card-qr {
    padding: 15px 10px;
  }

  .tc-card-content .tc-card-qr .tc-card-qr-inner .tc-card-qr-img {
    width: 80px;
    height: 80px;
  }

  .tc-sidebar-navbar {
    padding: 8px;
  }

  /* model section */
  .tc-model-header,
  .tc-model-body {
    padding: 10px;
  }

  .tc-model-body .tc-model-text p {
    font-size: 15px;
  }

  .tc-model-body .tc-model-text {
    margin-bottom: 20px;
  }

  .tc-model-dialog .modal-dialog {
    max-width: 300px;
  }

  .tc-model-body .tc-model-title {
    margin-bottom: 12px;
    font-size: 19px;
  }

  .tc-model-header .tc-model-header-title {
    font-size: 20px;
  }

  /* table section */
  .tc-table tr:first-child td {
    font-size: 13px;
  }

  .tc-table tr td {
    font-size: 12px;
    padding: 7px;
  }

  /* sidebar section */
  .tc-sidebar-title h2 {
    font-size: 19px;
  }

  .tc-sidebar-navbar svg {
    width: 20px;
    height: 20px;
  }

  .tc-table tr:first-child th {
    font-size: 14px;
  }

  .tc-main-modal .modal-title {
    font-size: 18px;
  }

  .tc-main-modal .modal-dialog {
    max-width: 300px;
  }

  .tc-quicksign-content .tc-quicksign-total {
    margin-bottom: 20px;
  }

  .tc-canvas-section .tc-canvas-time {
    font-size: 14px;
  }

  .tc-canvas {
    height: 300px;
    margin-bottom: 20px;
  }

  .tc-md-list-behavior-input-box
    .tc-call-summary-signature-box.tc-joint-call-signature-box
    .tc-join-call-digi-signaturepad,
  .tc-md-list-behavior-input-box .tc-join-call-digi-signaturepad,
  .tc-md-list-behavior-input-box
    .tc-call-summary-signature-box.tc-call-summary-photo-upload-img-box {
    height: 350px;
  }
}

@media (max-width: 400px) {
  .tc-footer {
    padding: 0 15px;
  }

  .tc-footer .tc-footer-content .tc-footer-tab {
    font-size: 9px;
    padding: 10px 4px;
  }

  .tc-footer .tc-footer-content .tc-footer-tab svg {
    width: 16px;
    height: 16px;
  }

  .tc-body {
    max-height: calc(100vh - 108px);
    height: calc(100vh - 108px);
  }

  .tc-navbar-content .tc-navbar-profile-dropdown .btn,
  .tc-navbar .tc-navbar-content .tc-navbar-user-name {
    font-size: 12px;
  }

  .tc-dashboard-report-col .tc-dashboard-report-col-list p {
    font-size: 16px;
  }
}
