/*  Font Rubik */
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

/* Common CSS Start */
:root {
  --bs-font-sans-serif: "Rubik", sans-serif;
  --bs-heading-color: var(--bs-black);
  --bs-body-color: var(--bs-black);
  --bs-primary: #115b48;
  --primary-dark: #105242;
  --primary-disabled: #115b48a6;
  --bs-primary-rgb: 17, 179, 111;
  --bs-red: #8b0018;
  --bs-red-dark: #820117;
  --bs-danger: #8b0018;
  --bs-gray-100: #f3f3f3;
  --bs-gray-200: #fafafa;
  --bs-gray-300: #d9d9d9;
  --bs-success: #4bb71b;
  --bs-failure: #f56060;

  --bs-link-color: var(--bs-primary);
  --bs-link-hover-color: var(--primary-dark);

  --tc-navbar: 48px;
  --tc-footer-blank: 48px;
  --tc-footer: 103px;
  --radius: 10px;
}

html {
  overscroll-behavior: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  font-family: "Rubik", sans-serif;
  overflow-x: auto;
  background-color: var(--bs-gray-100);
  padding-right: 0;
  height: 100%;
  letter-spacing: 0.1em;
}

.mb-6 {
  margin-bottom: 4rem !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h2,
.h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

h6 {
  font-size: 15px;
  font-weight: 600;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: rgb(var(--bs-primary-rgb), 0.2);
  border-radius: var(--radius);
  -webkit-border-radius: var(--radius);
  -moz-border-radius: var(--radius);
  -ms-border-radius: var(--radius);
  -o-border-radius: var(--radius);
}

::-webkit-scrollbar-thumb {
  background: rgb(var(--bs-primary-rgb), 0.6);
  border-radius: var(--radius);
  -webkit-border-radius: var(--radius);
  -moz-border-radius: var(--radius);
  -ms-border-radius: var(--radius);
  -o-border-radius: var(--radius);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(var(--bs-primary-rgb), 0.8);
}

ul,
ol {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

button {
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  letter-spacing: 0.1em;
}

input,
textarea,
:focus-visible,
.form-check-input:focus,
.form-select:focus,
.form-control:focus {
  box-shadow: none !important;
  outline: 0;
}

.form-control,
.form-select {
  background-color: var(--bs-gray-100);
}

.form-select:focus {
  background-color: var(--bs-white);
}

.form-control:focus,
.form-select:focus {
  border-color: rgb(var(--bs-primary-rgb), 0.3);
}

a:-webkit-any-link:focus-visible {
  outline: 0;
}

.form-check-input {
  background-color: var(--bs-gray-300);
  cursor: pointer;
}

.form-check-input:checked {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.form-check-input:focus {
  border-color: var(--bs-primary);
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 7C5.65685 7 7 5.65685 7 4C7 2.34315 5.65685 1 4 1C2.34315 1 1 2.34315 1 4C1 5.65685 2.34315 7 4 7Z' fill='%2311B36F' fill-opacity='0.5'/%3E%3C/svg%3E%0A");
}

.form-switch .form-check-input:checked {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
}

input[type="radio"].form-check-input {
  border: 2px solid var(--bs-primary);
}

.form-bordered-check-input.form-check-input {
  width: 20px;
  height: 20px;
  border: 3px solid var(--bs-primary);
  background-color: transparent;
}

.form-bordered-check-input.form-check-input:checked {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.btn {
  --bs-btn-padding-x: 20px;
  --bs-btn-padding-y: 8px;
  --bs-btn-font-size: 16px;
  --bs-btn-font-weight: 600;
  --bs-btn-line-height: 22px;
  --bs-border-radius: var(--radius);
  text-transform: uppercase;
}

.btn-primary {
  --bs-btn-color: var(--bs-gray-100);
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-color: var(--bs-gray-100);
  --bs-btn-hover-bg: var(--primary-dark);
  --bs-btn-hover-border-color: var(--primary-dark);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--bs-gray-100);
  --bs-btn-active-bg: var(--primary-dark);
  --bs-btn-active-border-color: var(--primary-dark);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--bs-gray-100);
  --bs-btn-disabled-bg: var(--bs-primary);
  --bs-btn-disabled-border-color: var(--bs-primary);
}

.btn-danger {
  --bs-btn-bg: var(--bs-red);
  --bs-btn-border-color: var(--bs-red);
  --bs-btn-hover-bg: var(--bs-red-dark);
  --bs-btn-hover-border-color: var(--bs-red-dark);
  --bs-btn-active-bg: var(--bs-red-dark);
  --bs-btn-active-border-color: var(--bs-red-dark);
  --bs-btn-disabled-bg: var(--bs-red);
  --bs-btn-disabled-border-color: var(--bs-red);
}

.btn-min {
  min-width: 144px;
}

.btn-group-sm > .btn,
.btn-sm {
  --bs-btn-padding-y: 10px;
  --bs-btn-padding-x: 12px;
  --bs-btn-font-size: 12px;
  --bs-btn-border-radius: var(--radius);
  line-height: normal;
}

button:disabled,
button.disabled {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.pointer-none {
  pointer-events: none !important;
}

.tc-cursor-pointer {
  cursor: pointer;
}

.fs-18 {
  font-size: 18px;
  line-height: 1.2;
}

.small,
small {
  font-size: 12px;
}

.card {
  --bs-card-border-radius: var(--radius);
  --bs-card-cap-bg: var(var(--bs-gray-100));
}

.card-footer {
  border-top: none;
}

.nav {
  --bs-nav-link-color: var(--bs-gray-100);
  --bs-nav-link-hover-color: var(--bs-gray-100);
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--radius);
  --bs-nav-pills-link-active-color: var(--bs-primary);
  --bs-nav-pills-link-active-bg: var(--bs-white);
}

.nav-pills .nav-item .nav-link {
  border: 3px solid transparent;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 600;
  background: var(--bs-primary);
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  border-color: var(--bs-primary);
  background: var(--bs-gray-100);
}

.tabs_underline.nav {
  --bs-nav-link-color: var(--bs-black);
  --bs-nav-link-hover-color: var(--bs-black);
}

.tabs_underline .nav-item {
  --bs-nav-tabs-border-width: 0;
  --bs-nav-tabs-border-color: transparent;
  --bs-nav-tabs-border-radius: 0;
  --bs-nav-tabs-link-hover-border-color: var(--bs-primary);
  --bs-nav-tabs-link-active-color: var(--bs-primary);
  --bs-nav-tabs-link-active-bg: transparent;
  --bs-nav-tabs-link-active-border-color: var(--bs-primary);
  border-bottom: 0px solid transparent;
}

.tabs_underline .nav-item .nav-link {
  border-bottom: 3px solid transparent;
  font-weight: 600;
  font-size: 18px;
}

.tabs_underline .nav-link.active,
.tabs_underline .show > .nav-link {
  border-color: var(--bs-primary);
}

.mb-30 {
  margin-bottom: 30px;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: var(--bs-gray-300);
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-white);
  background-color: var(--bs-primary);
}

.border-solid {
  border-style: solid !important;
}

/* Navigation CSS Start */
.tc-navbar,
.tc-navbar-back-text {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  /* max-width: 1024px; */
  height: var(--tc-navbar);
  background: var(--bs-red);
  margin: 0 auto;
  z-index: 2;
  padding: 12px;
  display: flex;
  align-items: center;
}

/* .tc-navbar-back-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
} */

.tc-navbar .tc-navbar-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.tc-navbar-back-btn,
.tc-sidebar-back-btn,
.tc-sidebar-right-btn {
  width: 25px;
  height: 25px;
  cursor: pointer;
  color: var(--bs-white);
  margin-right: auto;
}

.tc-sidebar-right-btn {
  margin-left: auto;
  margin-right: 0;
}

.tc-navbar-back-btn svg,
.tc-sidebar-back-btn svg,
.tc-sidebar-right-btn svg {
  width: 100%;
  height: 100%;
}

.tc-navbar-title {
  font-size: 22px;
  font-weight: 600;
  color: var(--bs-white);
  margin: 0 auto;
}

.tc-navbar .tc-navbar-content .tc-navbar-user-name {
  color: var(--bs-gray-100);
  font-size: 20px;
  font-weight: 600;
}

.tc-navbar .tc-navbar-content .tc-navbar-user-name img {
  width: 160px;
  height: auto;
}

.tc-navbar-content .tc-navbar-profile-dropdown .btn {
  color: var(--bs-white);
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  background: transparent;
  border-radius: 0;
  padding: 0;
  border: 0;
}

.tc-navbar-content .tc-navbar-profile-dropdown .btn::after {
  display: none;
}

.tc-navbar-content .tc-navbar-profile-dropdown .btn img {
  width: 35px;
  height: 35px;
  margin-left: 10px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.tc-navbar-content .tc-navbar-profile-dropdown .dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: var(--bs-black);
}

.tc-navbar-content .tc-navbar-profile-dropdown .dropdown-menu {
  width: fit-content;
  inset: unset !important;
  transform: unset !important;
  top: 100% !important;
  right: 10px !important;
}

/* Footer Navigation CSS Start */
.tc-footer-blank {
  background-color: var(--bs-primary);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  z-index: 99;
  /* max-width: 1024px; */
  height: 48px;
}

.tc-footer {
  padding: 22px 34px;
  background-color: var(--bs-primary);
  border-radius: var(--radius);
  -webkit-border-radius: var(--radius);
  -moz-border-radius: var(--radius);
  -ms-border-radius: var(--radius);
  -o-border-radius: var(--radius);
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  /* max-width: 970px; */
  width: 90%;
  margin: 0 auto;
  z-index: 999;
}

.tc-admin-footer {
  max-width: calc(100% - 60px);
}

.tc-footer .tc-footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tc-footer .tc-footer-content .tc-footer-tab {
  display: flex;
  position: relative;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  color: var(--bs-gray-100);
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.tc-footer .tc-footer-content .tc-footer-tab svg {
  width: auto;
  height: 35px;
  margin-bottom: 10px;
}

.tc-footer .tc-footer-content .tc-footer-tab.active {
  color: var(--bs-black);
}

.tc-footer .tc-footer-content .tc-footer-tab.active svg * {
  fill: var(--bs-black);
}

/* Side animate page */
.tc-sidebar-section {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: var(--bs-gray-100);
  width: 100%;
  max-height: 100vh;
  height: 100vh;
  z-index: 99;
  left: 100%;
  transition: 0.5s;
}

.tc-sidebar-section.zl-sidebar-open {
  left: 0;
}

.tc-sidebar-navbar {
  height: var(--tc-navbar);
  background: var(--bs-red);
  padding: 12px;
  display: flex;
  align-items: center;
}

.h-96 {
  height: 96%;
}

.tc-sidebar-body-wrapper {
  height: calc(100vh - var(--tc-navbar) - 40px);
  margin-bottom: 40px;
  padding: 12px 12px 0;
}

.tc-sidebar-without-ft .tc-sidebar-body-wrapper {
  height: calc(100vh - var(--tc-navbar) - var(--tc-navbar));
  padding: 23px 17px;
}

.tc-sidebar-without-ft .tc-sidebar-body-wrapper .tc-sidebar-body {
  padding: 32px 25px;
}

/* React Date picker */
.react-datepicker__input-container input::selection {
  background: transparent;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--bs-primary) !important;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  padding: 0 !important;
  fill: var(--bs-primary);
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

/* Bootstrap Table */
.table {
  --bs-table-bg: var(--bs-gray-300);
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
  vertical-align: middle;
}

.table > :not(caption) > * > * {
  padding: 12px 18px;
  border-bottom-width: 0;
}

/* .table> :not(caption)>*>* {
  padding: 8px 12px;
  border-bottom-width: 0;
} */

.table tbody td {
  text-align: center;
}

.table thead > tr {
  border-bottom: 1px solid var(--bs-gray-100);
}

.table thead > tr th {
  padding-bottom: 12px;
  text-transform: uppercase;
  white-space: nowrap;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.table tbody > tr td:not(:last-child),
.table tfoot > tr td:not(:last-child) {
  border-right: 1px solid var(--bs-gray-100);
}

.table tr td p {
  margin-bottom: 0;
}

.table tbody::before {
  content: "@";
  display: block;
  line-height: 25px;
  text-indent: -99999px;
}

.table tbody > tr:first-child td {
  padding-top: 0;
}

/* table.tc-table.tc-product-sampling.table tbody>tr td:first-child {
  text-align: start;
} */

.table .tc-md-list-table-qty-input {
  padding: 3px 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background-color: var(--bs-gray-100);
  border-color: var(--bs-gray-100);
  font-weight: 600;
  max-width: 50px;
  max-width: 50px;
  text-align: right;
  font-size: 14px;
  line-height: normal;
}

.tc-border-bottom-table tbody tr td:first-child {
  text-transform: uppercase;
}

.tc-border-bottom-table tbody > tr td:not(:last-child) {
  border-right: none;
}

.tc-border-bottom-table tbody::before {
  display: none;
}

.tc-border-bottom-table tbody > tr td:not(:last-child) {
  border-right: 1px solid var(--bs-gray-100);
}

.tc-border-bottom-table tbody > tr:first-child td {
  padding-top: 12px;
}

.popup-scroll.modal-body {
  max-height: 70vh;
  overflow: auto;
}

/* React Data Table */
.tc-data-table,
.tc-table-wrapper {
  background-color: var(--bs-gray-300);
  padding: 12px;
  border-radius: var(--radius);
  -webkit-border-radius: var(--radius);
  -moz-border-radius: var(--radius);
  -ms-border-radius: var(--radius);
  -o-border-radius: var(--radius);
  margin-bottom: 50px;
}

.tc-table-wrapper.tc-white-table-wrapper {
  background: #fff;
}

.tc-table-wrapper {
  background-color: var(--bs-gray-300);
  padding: 18px 22px 22px;
  border-radius: var(--radius);
  -webkit-border-radius: var(--radius);
  -moz-border-radius: var(--radius);
  -ms-border-radius: var(--radius);
  -o-border-radius: var(--radius);
}

/* .tc-body.tc-call-activities {
  margin-top: 50px;
} */

.tc-data-table .rdt_Table {
  background-color: var(--bs-gray-300);
}

.tc-data-table .rdt_TableHead {
  font-size: 14px;
  font-weight: 600;
  color: var(--bs-black);
  text-transform: uppercase;
}

.tc-data-table .rdt_TableHead .rdt_TableCol {
  justify-content: center;
}

.tc-data-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow {
  background-color: var(--bs-gray-300);
  min-height: auto;
  border-bottom-color: var(--bs-gray-100);
  padding-bottom: 12px;
}

.tc-data-table .rdt_TableBody .rdt_TableRow {
  border-bottom: 0;
  background-color: var(--bs-gray-300);
  font-size: 14px;
  font-weight: 600;
  color: var(--bs-black);
  min-height: auto;
}

.tc-data-table .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  padding-left: 18px;
  padding-bottom: 25px;
  justify-content: center;
}

.tc-data-table .rdt_TableBody .rdt_TableRow .rdt_TableCell:not(:last-child) {
  border-right: 1px solid var(--bs-gray-100);
}

.tc-data-table .rdt_TableBody {
  padding-top: 25px;
}

.tc-data-table .rdt_Table .rdt_TableCell > p {
  margin-bottom: 0;
}

.tc-data-table .rdt_Table .rdt_TableCol {
  /* padding-left: 18px; */
}

.tc-data-table .rdt_Table .rdt_TableHeadRow .rdt_TableCol:first-child,
.tc-data-table .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child {
  /* padding-left: 0; */
}

.tc-data-table
  .rdt_Table
  .rdt_TableHead
  .rdt_TableHeadRow
  .rdt_TableCol
  div
  span {
  display: none;
}

.tc-data-table-icon svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.tc-data-table-icon2 svg {
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.tc-table-edit-icon svg {
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.tc-data-table-icon3 svg {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.tc-data-table-icon3-nodata {
  min-width: 30px;
}

.tc-data-table-date .react-datepicker-wrapper {
  height: 0px;
}

.tc-md-birthday-modal .modal-content {
  border-radius: 35px;
  border: 3px solid var(--bs-primary);
  background-color: var(--bs-white);
}

.tc-md-birthday-modal .modal-content .modal-detail-content img {
  width: 130px;
  height: 190px;
}

.tc-md-birthday-modal .modal-content .modal-detail-content span {
  font-size: 14px;
  font-weight: 600;
  color: var(--bs-black);
}

.react-datepicker__header .react-datepicker__header__dropdown {
  margin-top: 8px !important;
}

.react-datepicker__header .react-datepicker__header__dropdown {
  margin-top: 8px !important;
}

.react-datepicker__header
  .react-datepicker__header__dropdown
  .react-datepicker__month-dropdown-container,
.react-datepicker__year-dropdown-container {
  border: 2px solid var(--bs-primary);
  border-radius: 5px;
  padding: 2px 0px;
  padding-left: 5px;
  padding-right: 22px;
}

.react-datepicker__header
  .react-datepicker__header__dropdown
  .react-datepicker__month-dropdown-container
  .react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-dropdown-container
  .react-datepicker__month-read-view--down-arrow {
  border-color: var(--bs-primary);
}

.react-datepicker__header
  .react-datepicker__header__dropdown
  .react-datepicker__year-option
  .react-datepicker__navigation {
  display: none;
}

/* Custom DatePicker */
.react-datepicker__header .tc-custom-date-picker-header button {
  border: 2px solid var(--bs-black) !important;
  padding: 2px 5px !important;
  font-weight: 800;
  color: var(--bs-black);
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  color: var(--bs-white) !important;
}

/* React Select */
.tc-custom-select .select__control {
  background-color: var(--bs-gray-100);
  border-color: var(--bs-gray-100);
  border-radius: 5px;
}

.tc-custom-select .select__control:hover {
  border-color: var(--bs-gray-100);
}

.tc-custom-select .select__indicator-separator {
  display: none;
}

.tc-custom-select .select__indicator,
.tc-custom-select .select__indicator:hover {
  color: var(--bs-black);
}

.tc-custom-select .select__control.select__control--is-focused {
  border-color: var(--bs-primary) !important;
  box-shadow: none;
  background-color: var(--bs-white);
}

.tc-custom-select-error .select__control.select__control--is-focused {
  border-color: var(--bs-red) !important;
}

.tc-custom-select .select__option.select__option--is-focused {
  background-color: rgb(var(--bs-primary-rgb), 0.3);
}

.tc-custom-select .select__option.select__option--is-selected {
  background-color: var(--bs-primary);
}

.tc-custom-select-2 .select__control {
  border-color: var(--bs-gray-100);
  background-color: var(--bs-primary);
  box-shadow: none !important;
  min-width: 200px;
}

.tc-custom-select-sm .select__control {
  min-width: 150px;
}

.tc-custom-select-2-error .select__control {
  border-left: 8px solid var(--bs-primary) !important;
  border: 2px solid var(--bs-primary) !important;
  border-radius: 6px;
  box-shadow: none !important;
}

.tc-custom-select-2 .select__control .select__placeholder {
  font-size: 16px;
  font-weight: 400;
  color: var(--bs-white);
}

.tc-custom-select-2 .select__control .select__input-container .select__input {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: var(--bs-white) !important;
  border: 0 !important;
}

.tc-custom-select-2 .select__indicator-separator {
  display: none;
}

.tc-custom-select-2
  .select__control
  .select__indicators
  .select__dropdown-indicator {
  height: 36px;
  position: relative;
  width: 36px;
}

.tc-custom-select-2
  .select__control
  .select__indicators
  .select__dropdown-indicator:before {
  border: 10px solid transparent;
  border-top: 12px solid var(--bs-white);
  content: "";
  left: 50%;
  position: absolute;
  top: 12px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.tc-custom-select-2 .select__single-value {
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
}

.tc-custom-select-2 .select__option.select__option--is-focused {
  background-color: rgb(var(--bs-primary-rgb), 0.3);
}

.tc-custom-select-2 .select__option.select__option--is-selected {
  background-color: var(--bs-primary);
}

/* Body CSS Start */
.tc-container {
  position: relative;
  width: 100%;
  /* max-width: 1024px; */
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  height: 100vh;
}

.tc-body-wrapper {
  height: calc(100vh - var(--tc-navbar) - 40px);
  margin-top: var(--tc-navbar);
  margin-bottom: 40px;
  padding: 12px 12px 0;
}

.tc-body-wrapper .tc_main_version_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tc-body-wrapper .tc_main_version_content {
  font-size: 13px;
  font-weight: 600;
  color: var(--bs-primary);
}

.tc-body,
.tc-sidebar-body {
  background-color: var(--bs-gray-300);
  height: 100% !important;
  /* overflow-y: auto; */
  border-radius: var(--radius);
  -webkit-border-radius: var(--radius);
  -moz-border-radius: var(--radius);
  -ms-border-radius: var(--radius);
  -o-border-radius: var(--radius);
  padding: 20px 20px 20px;
  display: flex;
  flex-direction: column;
}

/* .tc-body-without-ft {
  padding: 32px 25px;
} */

.tc-profile-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.tc-profile-wrapper img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-right: 16px;
}

.tc-profile-wrapper .tc-profile-name {
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
  color: var(--bs-black);
}

.tc-profile-wrapper .tc-header-refresh svg {
  width: 40px;
  height: 40px;
  fill: var(--bs-primary);
  margin-left: 3px;
}

.tc-content-wrapper {
  background-color: var(--bs-white);
  padding: 15px;
  border-radius: var(--radius);
  -webkit-border-radius: var(--radius);
  -moz-border-radius: var(--radius);
  -ms-border-radius: var(--radius);
  -o-border-radius: var(--radius);
  flex: 1;
  display: flex;
  flex-direction: column;
  /* height: 100%; */
}

.tc-content-wrapper .tc-content-wrapper-title {
  margin: 10px 0px;
  align-items: center;
}

.tc-content-wrapper .tc-content-wrapper-title.extra-padding {
  margin: 30px 0px;
}

.tc-datanot-found p {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  text-align: center;
}

.tc-datanot-found img {
  max-width: 230px;
  width: 100%;
  display: block;
  margin: 0 auto 15px;
}

/* MD LIST Page CSS */
.tc-calender-search-input-row {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  margin-top: 10px;
}

.report-table-tab,
.tab-content,
.tab-content > div {
  height: 100%;
}

.tab-content {
  height: 340px;
  overflow: auto;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.tc-calender-search-input-row .tc-calender-input-content {
  width: 100%;
}

.tc-date-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.tc-calender-search-input-row .calendar-icon-md {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.tc-calender-search-input-row .calendar-icon-md svg {
  margin-left: 10px;
  width: 15px;
  height: 15px;
}

.tc-calender-search-input-row .tc-atoz-icon svg {
  margin-left: 10px;
  width: 26px;
  height: 26px;
}

.calendar-icon-md label {
  cursor: pointer;
}

.tc-atoz-icon {
  cursor: pointer;
}

.tc-calender-search-input-row .tc-calender-input-content input {
  max-width: 90px;
  width: 100%;
  background: var(--bs-gray-300);
  border: 1px solid var(--bs-gray-300);
  font-size: 14px;
  font-weight: 600;
  padding: 5px 8px;
  /* padding-right: 30px; */
  color: var(--bs-black);
  -webkit-text-fill-color: var(--bs-black);
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-progress-appearance: none;
  border-radius: var(--radius);
  -webkit-border-radius: var(--radius);
  -moz-border-radius: var(--radius);
  -ms-border-radius: var(--radius);
  -o-border-radius: var(--radius);
}

.tc-custom-date-picker-input {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  background: var(--bs-gray-300);
  padding: 0px 10px 0 5px;
  border-radius: 10px;
  cursor: pointer;
}

.tc-custom-date-picker-input .date-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.tc-calender-search-input-row .tc-search-input-content {
  max-width: 147px;
  width: 100%;
  margin-left: auto;
  position: relative;
}

.tc-calender-search-input-row .tc-search-input-content input {
  width: 100%;
  background: var(--bs-gray-300);
  border: none;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 40px 5px 11px;
  color: var(--bs-black);
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-progress-appearance: none;
  border-radius: var(--radius);
  -webkit-border-radius: var(--radius);
  -moz-border-radius: var(--radius);
  -ms-border-radius: var(--radius);
  -o-border-radius: var(--radius);
}

.tc-calender-search-input-row .tc-search-input-content .tc-search-input-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tc-data-table
  .rdt_Table
  .rdt_TableHead
  .rdt_TableHeadRow
  .rdt_TableCol:nth-child(2),
.tc-data-table
  .rdt_Table
  .rdt_TableBody
  .rdt_TableRow
  .rdt_TableCell:nth-child(2) {
  /* min-width: 240px; */
  flex-grow: 2;
}

.tc-data-table .rdt_Table .rdt_TableBody .tc-data-table-md-name-text {
  background-color: var(--bs-primary);
  color: var(--bs-gray-100);
  padding: 12px 12px;
  width: 100%;
  /* max-width: 270px; */
  border-radius: var(--radius);
  -webkit-border-radius: var(--radius);
  -moz-border-radius: var(--radius);
  -ms-border-radius: var(--radius);
  -o-border-radius: var(--radius);
}

.tc-table-row-disabled {
  opacity: 0.4;
  pointer-events: none;
}

.tc-table tr td.tc-md-list-table-heading {
  text-align: left;
}

/* MD call activity page */
.tc-md-list-common-detail {
  background-color: var(--bs-gray-300);
  margin-bottom: 50px;
  padding: 10px 15px;
  border-radius: var(--radius);
  -webkit-border-radius: var(--radius);
  -moz-border-radius: var(--radius);
  -ms-border-radius: var(--radius);
  -o-border-radius: var(--radius);
  width: max(50%, 424px);
}

.tc-md-list-common-detail h6 {
  border-bottom: 1px solid var(--bs-gray-100);
}

.tc-md-list-common-detail .tc-md-list-common-detail-row {
  display: flex;
  align-items: center;
  font-size: 15px;
}

.tc-md-list-common-detail
  .tc-md-list-common-detail-row
  .tc-md-list-common-detail-label {
  font-weight: 600;
}

.tc-md-list-common-detail
  .tc-md-list-common-detail-row
  .tc-md-list-common-detail-text {
  margin-bottom: 0;
  text-transform: capitalize;
  padding-left: 5px;
}

.tc-activitie-md-detail .tc-activitie-md-detail-row {
  font-size: 15px;
}

.tc-activitie-md-detail
  .tc-activitie-md-detail-row
  .tc-activitie-md-detail-label {
  font-weight: 600;
}

.tc-activitie-md-detail
  .tc-activitie-md-detail-row
  .tc-activitie-md-detail-text {
  text-transform: capitalize;
  padding-left: 5px;
}

.tc-call-activity-list .tc-call-activity-list-row {
  border: 3px solid var(--bs-primary);
  color: var(--bs-gray-100);
  padding: 35px 21px 35px 21px;
  border-radius: var(--radius);
  -webkit-border-radius: var(--radius);
  -moz-border-radius: var(--radius);
  -ms-border-radius: var(--radius);
  -o-border-radius: var(--radius);
  display: flex;
  align-items: center;
  gap: 5px;
}

/* .tc-call-activity-list .tc-call-activity-list-row + .tc-call-activity-list-row {
  margin-top: 15px;
} */
.tc-call-activity-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px 25px;
  /* height: 100%;
  overflow: auto; */
  padding-right: 10px;
}

.tc-call-activity-list .tc-call-activity-list-row h5 {
  border-right: 1px solid var(--bs-white);
  padding-right: 10px;
  margin-right: 30px;
  text-transform: uppercase;
  min-width: 230px;
  font-size: 22px;
}

.tc-call-activity-list .tc-call-activity-list-row.active {
  background-color: var(--bs-primary);
}

.tc-call-activity-list .tc-call-activity-list-row.active h5 {
  color: var(--bs-gray-100);
}

.tc-call-activity-list .tc-call-activity-list-row.disabled {
  background-color: var(--primary-disabled);
  border: 3px solid var(--primary-disabled);
  pointer-events: none;
}

.tc-call-activity-list .tc-call-activity-list-row.disabled h5 {
  color: var(--bs-gray-100);
}

.tc-call-activity-list
  .tc-call-activity-list-row.disabled
  .tc-activity-check-btn
  svg {
  opacity: 0.8 !important;
}

.tc-join-call-table thead tr th:first-child {
  min-width: 200px;
}

.tc-product-sampling .color-gray-table td {
  pointer-events: none;
}

.tc-product-sampling .color-gray-table td input {
  background-color: #afacac !important;
}

.tc-activity-check-btn svg {
  opacity: 0;
}

.tc-call-activity-list
  .tc-call-activity-list-row.active
  .tc-activity-check-btn
  svg {
  opacity: 1;
}

.tc-md-list-behavior-input-box .tc-join-call-digi-signaturepad {
  width: 100%;
  height: 220px;
  /* padding: 0 30px 0 0; */
  background-color: var(--bs-white);
  border-radius: var(--radius);
  -webkit-border-radius: var(--radius);
  -moz-border-radius: var(--radius);
  -ms-border-radius: var(--radius);
  -o-border-radius: var(--radius);
}

.tc-social-platform-dropdown .dropdown-toggle {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: var(--bs-white);
  padding: 0;
  width: 24px;
  height: 24px;
}

.tc-social-platform-dropdown .dropdown-toggle::after {
  display: none;
}

.tc-quick-sign-signature-box .tc-quicksign-signature-zoom-in {
  display: block;
  position: absolute;
  right: 10px;
  top: 10px;
}

/* .tc-quick-sign-signature-box .tc-quicksign-signature-zoom-in svg {
  width: 15px;
  height: 15px;
} */
.tc-btn-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tc-btn-wrapper .btn {
  max-width: 110px;
}

.tc-quick-sign-btn-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
}

.tc-call-summary-photo-upload-box {
  min-height: 144px;
  background: var(--bs-white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: var(--radius);
  -webkit-border-radius: var(--radius);
  -moz-border-radius: var(--radius);
  -ms-border-radius: var(--radius);
  -o-border-radius: var(--radius);
}

.tc-call-summary-photo-upload-img {
  height: 200px;
}

.tc-signature-pad-icons {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 96%;
  justify-content: space-between;
  padding: 10px;
}

.tc-signature-pad-icons svg {
  width: 15px;
  height: 15px;
}

.tc-quicksign-date-camera-row .tc-quicksign-camera-btn svg {
  width: 24px;
  height: 24px;
}

.tc-quicksign-date-camera-row .tc-quicksign-camera-btn {
  margin-left: auto;
}

.tc-md-reliever-check {
  display: flex;
  align-items: center;
  margin-left: 0px;
  text-align: center;
  margin: 0;
  padding: 0;
}

.tc-md-reliever-check input {
  border: 2px solid;
  font-size: 20px;
  text-align: center;
  margin: 0 !important;
  padding: 0;
}

.tc-md-reliever-check label {
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
}

.tc-call-edit-disabled {
  pointer-events: none;
}

.tc-call-edit-disabled svg {
  opacity: 0.5;
}

.tc-product-detailing-table .tc-md-reliever-check input {
  font-size: 20px;
  background-color: var(--bs-white);
  border-color: var(--bs-primary);
}

.tc-product-detailing-table .tc-md-reliever-check input:checked {
  background-color: var(--bs-primary) !important;
}

/* Modal */
.modal {
  --bs-modal-bg: var(--bs-gray-300);
  --bs-modal-header-padding: 1.5rem 1.5rem 1rem;
  --bs-modal-padding: 1.5rem;
}

.tc-modal-white.modal {
  --bs-modal-bg: var(--bs-gray-100);
}

.tc-main-modal .modal-header {
  border: none;
}

.tc-modal-md.modal {
  --bs-modal-width: 440px;
}

.tc-modal-md .tc-file-upload-input input {
  bottom: unset !important;
}

.tc-main-modal .modal-title {
  font-size: 18px;
  font-weight: 600;
  color: var(--bs-primary);
  margin-bottom: 0;
  text-transform: uppercase;
  border-bottom: 1px solid var(--bs-black);
  width: 100%;
  padding-bottom: 10px;
}

.tc-modal-radius .modal-content {
  border-radius: 35px;
  border: 3px solid var(--bs-primary);
  background-color: var(--bs-white);
}

.tc-modal-quicksign-signature-box {
  position: relative;
}

.tc-quicksign-signature-zoom-in svg {
  width: 35px;
  height: 35px;
}

.tc-main-modal-sm .modal-dialog {
  max-width: 288px !important;
  margin: 0 auto;
}

.tc-main-modal-sm.modal {
  --bs-modal-header-padding: 20px;
  --bs-modal-padding: 20px;
}

.modal-checkbox-list .form-check {
  padding: 10px 0;
  margin: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.modal-checkbox-list .form-check .form-check-input {
  float: unset;
  margin-left: 0;
  margin-top: 0;
}

.modal-checkbox-list label {
  padding-right: 10px;
}

.tc-main-modal p {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
}

.tc-main-modal .tc_sign_back_btn svg {
  width: 24px;
}

.tc-product-pdf-modal .tc-custom-select-2 {
  width: 50%;
}

.tc-product-pdf-modal .modal-dialog-centered {
  /* min-height: calc(100vh - var(--tc-navbar) - var(--tc-footer)) !important; */
}

.tc-product-pdf-modal .modal-dialog {
  max-width: 90vw;
  max-height: 80vh;
}

.tc-product-pdf-modal .modal-body {
  display: flex;
  flex-direction: column;
}

.images-wrap {
  /* height: 67vh; */
}

.react-slideshow-container + ul.indicators li {
  width: 13px !important;
}

.tc-product-pdf-modal .tc-product-pdf-modal-img,
.tc-product-pdf-modal .tc-product-modal-img-wrapper {
  flex-grow: 1;
}

.tc-product-pdf-modal .tc-product-modal-img-wrapper {
  text-align: center;
  padding: 0px 2px;
}

.tc-product-pdf-modal .tc-full-img-height img,
.tc-product-pdf-modal .react-pdf__Document {
  max-height: calc(50vh + 180px) !important;
  min-height: calc(50vh + 180px) !important;
}

.react-pdf__Document {
  min-height: 50vh;
  max-height: 50vh;
  overflow-y: auto;
  max-width: 90%;
  margin: auto;
}

.react-pdf__Page {
  padding: 10px;
  background: #363d3e !important;
}

canvas.react-pdf__Page__canvas {
  margin: auto;
  max-width: 90%;
  width: 100% !important;
  height: auto !important;
}

.tc-product-pdf-modal .tc-product-pdf-modal-img {
  min-height: 500px;
  width: 90%;
  overflow-y: auto;
}

.tc-product-pdf-modal .react-slideshow-wrapper.slide {
  overflow-y: auto !important;
}

.tc-product-pdf-modal .tc-product-modal-img {
  max-height: 50vh;
  min-height: 50vh;
}

.tc-product-pdf-modal .tc-sub-product-img {
  display: flex;
  justify-content: space-evenly;
  border: 2px solid var(--bs-primary);
  margin-top: 12px;
  padding: 5px 10px;
  border-radius: var(--bs-border-radius);
  background-color: var(--bs-white);
  gap: 20px;
  overflow-x: auto;
  text-align: center;
}

.tc-product-pdf-modal .tc-sub-product-img img {
  height: 150px;
  width: 150px;
}

.tc-product-pdf-modal .tc-nosub-product-img {
  border: none;
  background-color: var(--bs-gray-300);
  min-height: 180px;
}

.tc-product-pdf-modal .modal-body > div,
.tc-product-pdf-modal .react-slideshow-container {
  height: 100%;
}

.tc-product-pdf-modal .modal-dialog .modal-content {
  /* height: calc(100vh - var(--tc-navbar) - 10px) !important; */
}

.tc-product-pdf-modal .modal-footer {
  border: 0;
}

.react-slideshow-container .default-nav {
  background: var(--bs-primary) !important;
}

.react-slideshow-container .default-nav svg {
  width: 20px;
  height: 20px;
  fill: var(--bs-white);
}

.indicators {
  margin-top: 10px !important;
}

.indicators li {
  background: white;
  border-radius: 10px;
  height: 13px;
  border: 2px solid black;
  margin-right: 8px !important;
}

.react-slideshow-container + ul.indicators .each-slideshow-indicator:before {
  top: 1px !important;
  left: 1px !important;
  width: 8px !important;
  height: 8px !important;
  padding-right: 2px !important;
  background: var(--primary-dark) !important;
}

/* Dashboard CSS */
.apexcharts-canvas text[font-family="Cabin, sans-serif"] {
  font-weight: 600;
}

.tc-dashboard-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tc-dashboard-header h6 {
  color: var(--bs-red);
  font-size: 16px;
  font-weight: 700;
}
.tc-dashboard-header .color-green {
  color: var(--bs-primary);
}

.report-table {
  max-height: 225px;
  height: 100%;
}

.report-table .tc-table-wrapper {
  height: 100%;
}

.without-chart {
  max-height: 460px;
  height: 100%;
}

.tc-chart-content {
  /* display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  overflow-x: auto;
  gap: 50px; */
  /* margin-bottom: 50px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.tc-chart-content .tc-chart-container {
  /* width: 500px; */
  background-color: var(--bs-primary);
  color: var(--bs-gray-100);
  padding: 10px 10px;
  border-radius: var(--radius);
  -webkit-border-radius: var(--radius);
  -moz-border-radius: var(--radius);
  -ms-border-radius: var(--radius);
  -o-border-radius: var(--radius);
}

.tc-chart-content .tc-promo-mats-chart-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  background-color: var(--bs-primary);
  color: var(--bs-gray-100);
  padding: 15px 20px;
  border-radius: var(--radius);
  -webkit-border-radius: var(--radius);
  -moz-border-radius: var(--radius);
  -ms-border-radius: var(--radius);
  -o-border-radius: var(--radius);
}

.tc-dashboard-table .table,
.tc-dashboard-table .table th,
.tc-class-table .table th {
  text-align: center;
}

.tc-dashboard-table .table tfoot td {
  color: var(--bs-primary);
}

.tc-dashboard-table .table thead th {
  font-size: 12px;
}

.tc-class-table {
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
}

.tc-class-table .table {
  --bs-table-bg: transparent;
  text-align: center;
}

.tc-class-table .table thead tr {
  border: none;
}

.tc-class-table .table th,
.tc-class-table .table td {
  padding-left: 0;
  padding-right: 0;
}

.tc-class-table .table td .tc-class-border {
  position: relative;
  padding: 0 5px;
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.tc-class-table .table td .tc-class-border::after,
.tc-class-table .table td .tc-class-border::before {
  content: "";
  height: 1px;
  width: 100%;
  background-color: var(--bs-black);
}

.tc-class-table .table tfoot td .tc-class-border::after,
.tc-class-table .table tfoot td .tc-class-border::before {
  width: max(20%, 5px);
}

.min-width-50 {
  min-width: 50px;
}

.min-width-100 {
  min-width: 100px;
}

.min-width-150 {
  min-width: 150px;
}

.min-width-180 {
  min-width: 180px;
}

.min-width-200 {
  min-width: 200px;
}

.min-width-250 {
  min-width: 270px;
}

.min-width-300 {
  min-width: 300px;
}

.min-width-350 {
  min-width: 350px;
}

.min-width-400 {
  min-width: 410px;
}

.min-width-450 {
  min-width: 450px;
}

.tc-error-text {
  display: flex;
  color: #f00000;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
}

.text-green {
  color: #17af6d !important;
}

.text-red {
  color: #ff0000 !important;
}

/* Quick Sign */
.tc-quicklist-canvas {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--bs-white);
  border-radius: var(--radius);
  -webkit-border-radius: var(--radius);
  -moz-border-radius: var(--radius);
  -ms-border-radius: var(--radius);
  -o-border-radius: var(--radius);
  min-height: 220px;
  height: 100%;
}

.tc-quicklist-canvas img {
  max-height: 220px;
  margin: auto;
  width: 85%;
  height: 100%;
}

.tc-quicklist-canvas .tc_group_camera {
  position: unset;
}
.tc-quicklist-canvas .tc_group_camera svg {
  width: 100px;
  height: 100px;
}

.height-300 {
  height: 300px !important;
}

.max-height-300 {
  max-height: 300px !important;
}

.height-150 {
  height: 150px !important;
}

.height-280 {
  height: 280px !important;
}

.min-height-280 {
  height: 280px !important;
}

.height-250,
.min-height-250 {
  height: 250px !important;
}

.min-height-290 {
  height: 290px !important;
}

.min-height-300 {
  height: 300px !important;
}

.min-height-320 {
  height: 320px !important;
}

.min-height-390 {
  height: 390px !important;
}

.min-height-370 {
  height: 370px !important;
}

.max-height-150 {
  max-height: 150px !important;
}

.min-height-150 {
  min-height: 150px !important;
}

.tc-sign-rotate {
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
}

.tc-sign-rotate svg {
  height: 24px;
  width: 24px;
}

.tc-sign-edit {
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
}

.tc-sign-edit svg {
  width: 24px;
  height: 24px;
}

.tc-quicksign-content .tc-sign-call-count {
  font-size: 15px;
  position: absolute;
  top: 12px;
  right: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bs-white);
  background: #66b573;
  transform: translate(0, -50%);
}

.tc-sign-camera svg {
  width: 30px;
  height: 35px;
}

.tc-quicksign-list-box {
  min-height: 256px;
}

.tc-quicksign-list-box .btn-sm {
  min-width: 83px;
}

/* PROMO MATERIALS */
.tc-promo-materials-content .nav-pills .nav-item .nav-link {
  min-width: 110px;
}

.tc-promo-materials-content h5 {
  font-size: 18px;
}

.tc-promo-materials-content .btn-link {
  text-decoration: none;
  color: var(--bs-black);
}

.tc-promo-materials-content .btn-link.active {
  color: var(--bs-primary);
}

.tc-promo-mats-table thead tr th:first-child {
  min-width: 270px;
}

/* Out of Field */
.tc-field-content .tc-table thead th:first-child {
  /* width: 55%; */
}

.tc-field-content .tc-table-wrapper:not(:last-child) {
  margin-bottom: 34px;
}

.tc-field-content h5 {
  margin-bottom: 10px;
}

.tc-table-wrapper {
  padding: 6px;
  /* height: 100%; */
}

.out-of-field-page .table > :not(caption) > * > * {
  padding: 5px;
}

.out-of-field-page .table {
  letter-spacing: 0;
}

.out-of-field-page .table tbody > tr:first-child td:first-child {
  padding-right: 10px;
}

.tc-out-of-field-md-modal-checkbox-list {
  background-color: var(--bs-gray-100);
  border-radius: var(--radius);
  -webkit-border-radius: var(--radius);
  -moz-border-radius: var(--radius);
  -ms-border-radius: var(--radius);
  -o-border-radius: var(--radius);
  padding: 5px 10px;
}

.tc-out-of-field-md-modal-checkbox-list .form-check-input {
  width: 20px;
  height: 20px;
  border: 3px solid var(--bs-primary);
  background-color: transparent;
}

.tc-out-of-field-md-modal-checkbox-list .form-check-input:checked {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.tactical-body {
  margin: auto;
  width: 420px;
}

.tc-out-of-field-image-capture {
  min-height: 150px;
  border-radius: 10px;
  background: white;
  font-weight: 500;
  font-size: 16px;
  color: #6b7280;
}

.tc-out-of-field-image-capture svg {
  width: 50px;
  height: 50px;
}

.tc-out-of-field-image-capture .tc-choose-text {
  color: var(--bs-black);
  font-weight: 600;
}

/* Login */
.tc-login-section {
  height: 100%;
}

.tc-login-content {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - var(--tc-navbar) - var(--tc-navbar));
  margin: 48px 0;
  background-color: var(--bs-white);
}

.tc-login-form {
  /* max-width: 500px; */
  margin: 0 auto;
  /* margin-top: -120px; */
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.tc-login-form-list {
  max-width: 400px;
  margin: auto;
}

.logo-title h2 {
  font-size: 55px;
  color: var(--bs-red);
}

.logo-title h2 span {
  color: var(--bs-primary);
}

.tc-login-logo {
  border: 5px solid var(--bs-red);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 15px;
  margin-bottom: 30px;
}

.version p {
  margin: 0;
  text-align: center;
  font-weight: 500;
}

.tc-login-form .login-button svg {
  width: 22px;
  height: 22px;
  margin-left: 12px;
}

/* .tc_version_content {
  margin: auto;
  text-align: center;
  color: var(--bs-white);
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0px;
} */

/* Report */
.tc-report-contents .tc-report-container {
  text-align: center;
}

.tc-reports-title-name {
  font-size: 25px;
  line-height: 43px;
  font-weight: 600;
  color: var(--bs-black);
}

/* .tc-report-list {
  max-width: 500px;
  margin: auto;
} */

.tc-report-list .btn:not(:last-child) {
  margin-bottom: 30px;
}

.tc-report-header .tc-report-header-date svg {
  width: 24px;
  height: 24px;
  margin-left: 5px;
}

.tc-report-header .tc-report-header-date svg * {
  fill: var(--bs-primary);
}

.tc-report-contents
  .tc-report-container
  .tc-report-list
  .tc-report-item-label-disabled {
  opacity: 0.4;
  pointer-events: none;
}

/* Time IN OUT */
.time-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 6px solid var(--bs-red);
  width: 310px;
  padding: 30px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.time-container h1 {
  font-size: 28px;
}

.time-options label {
  font-weight: 600;
  font-size: 24px;
}

.time-options label input {
  margin-right: 5px;
}

.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.tc-time-table thead tr th:first-child {
  width: 110px;
}

.tc-time-table thead tr th:nth-child(2) {
  width: 130px;
}

.tc-timeinout-bottom-btn {
  /* margin-top: 1.8rem; */
  text-align: center;
  display: flex;
  justify-content: center;
}

.tc-timeinout-bottom-btn button {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tc-timeinout-bottom-btn button svg {
  margin-bottom: 5px;
  width: 24px;
  height: 24px;
}

/* Itinerary CSS */
.tc-card-itinerary-container {
  width: 65%;
  /* padding-right: 20px;
  border-right: 1px solid #ccc; */
}

.tc-card-itinerary-container .tc-card-input-container .color-green {
  background-color: var(--bs-primary);
  color: var(--bs-white);
}

.dashed-br-line {
  display: block;
  content: "";
  border-bottom: 4px dashed var(--bs-red);
  margin: 16px 0px;
}

.tc-main-modal .tc-itinerary-approval-content .modal-detail-main-text {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 4px;
  color: var(--bs-red);
  text-align: center;
}

.tc-main-modal .tc-itinerary-approval-content .modal-detail-sub-text {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
  text-align: center;
}

.bg-color-gray {
  background-color: #e5e5e5;
}

.error-active {
  border: 2px solid red !important;
  border-radius: 4px !important;
}

/* Admin CSS */
.tc-container.tc-admin-container,
.tc-admin-container .tc-navbar-back-text {
  max-width: 100%;
}

.tc-itinerary-select-row .tc-custom-select {
  max-width: fit-content;
}

.tc-calender-input-content .text-select-option {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.tc-white-table-wrapper {
  background-color: var(--bs-gray-100);
}

.tc-white-table-wrapper .table {
  --bs-table-bg: transparent;
}

.tc-white-table-wrapper .table tbody > tr:first-child td {
  padding-top: 12px;
}

.tc-white-table-wrapper .table tbody > tr td:not(:last-child),
.tc-white-table-wrapper .table tfoot > tr td:not(:last-child) {
  border-color: var(--bs-gray-300);
}

.tc-white-table-wrapper .table tbody > tr td:first-child,
.tc-white-table-wrapper .table tfoot > tr td:first-child {
  border-right-color: transparent;
}

.tc-white-table-wrapper tbody::before {
  display: none;
}

.tc-white-table-wrapper .table tbody td {
  font-weight: 400;
}

.btn-row-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

/* Admin calls CSS */
.tc-calls-container .tc-content-wrapper span {
  font-weight: 500;
}

.tc-calls-container .react-datepicker-wrapper input {
  width: 100%;
  padding: 2px;
  font-size: 16px;
  font-weight: 400;
  color: var(--bs-black);
  padding: 6px 8px;
  border: 1px solid var(--bs-red);
  border-radius: 4px;
}

.tc-calls-container .tc-custom-select-2 .select__control {
  border-color: var(--bs-red);
  background-color: var(--bs-white);
  border-width: 1px;
}

.tc-calls-container .tc-custom-select-2 .select__control .select__placeholder {
  font-size: 16px;
  font-weight: 400;
  color: var(--bs-black);
}

.tc-calls-container
  .tc-custom-select-2
  .select__control
  .select__input-container
  .select__input {
  color: var(--bs-black) !important;
}

.tc-calls-container
  .tc-custom-select-2
  .select__control
  .select__indicators
  .select__dropdown-indicator:before {
  border-top: 12px solid var(--bs-red);
}

.tc-calls-container .tc-custom-select-2 .select__single-value {
  color: #393939;
  font-weight: 500;
}

.tc-calls-container .date-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  fill: var(--bs-red);
}

/* Admin auditReport CSS */
.tc-audit-report-container .tc-card-input-container {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-evenly;
}

.tc-audit-report-container .tc-card-input-label {
  display: flex;
  align-items: center;
}

.tc-audit-report-container .tc-card-input-label span {
  padding-right: 2px;
  font-weight: 500;
}

.tc-audit-report-container .react-datepicker-wrapper {
  display: block !important;
}

.tc-audit-report-container
  .react-datepicker-wrapper
  .react-datepicker__input-container {
  display: block !important;
}

.tc-audit-report-container svg {
  width: 24px;
  height: 24px;
  margin-left: 5px;
  fill: var(--bs-primary);
}

.tc-audit-report-container svg * {
  fill: var(--bs-primary);
}

.tc-audit-report-container .tc-audit-date-disabled svg * {
  opacity: 0.5;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

/* Admin other CSS */
.tc-others-row .tc-navbar-title {
  font-size: 20px;
}

.tc-file-upload-input {
  min-height: 150px;
  border-radius: 10px;
  background: white;
  font-weight: 500;
  font-size: 16px;
  color: #6b7280;
}

.tc-file-upload-input input {
  opacity: 0;
  position: absolute;
}

.tc-file-upload-input .tc-file-upload-label {
  position: relative;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  text-align: center;
  min-height: 150px;
  /* padding: 0px 20px; */
  width: 100%;
}

.tc-file-upload-input .tc-choose-content {
  border: 2px solid #ccc;
  border-style: dashed;
  padding: 8px 10px;
  border-radius: 8px;
  cursor: pointer;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tc-selected-file.tc-choose-content img {
  height: 150px;
  object-fit: contain;
}

.tc-file-upload-input .tc-file-upload-label svg {
  width: 25px;
  height: 25px;
}

.tc-file-upload-input .tc-choose-text {
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  padding: 0px 10px;
}

.tc-selected-file-container .tc-selected-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tc-selected-file-container .tc-selected-file span {
  font-size: 15px;
  font-weight: 500;
}

.tc-selected-file-container .tc-selected-file svg {
  cursor: pointer;
}

/* Dm CSS */
.tc-itinerary-body-section {
  position: relative;
}

.tc-itinerary-body-section .tc-body {
  padding: 0;
}

.tc-itinerary-body-section .tc-calender-search-input-row.tc-tabs-btn {
  position: absolute;
  right: 25px;
  top: 32px;
  z-index: 9;
}

.tc-fileupload-success-modal svg {
  width: 130px;
  height: 130px;
}

/* Success icon animation */
.tc-success-checkmark {
  border-radius: 50%;
  display: block;
  stroke-width: 3;
  stroke: var(--bs-success);
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px var(--bs-success);
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.5s both;
  position: relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}

.tc-success-checkmark-circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 3;
  stroke-miterlimit: 10;
  stroke: var(--bs-success);
  fill: #fff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.tc-success-checkmark-check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

/* Failure icon animation */
.tc-failure-checkmark {
  border-radius: 50%;
  display: block;
  stroke-width: 3;
  stroke: var(--bs-failure);
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px var(--bs-failure);
  animation: fill2 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.5s both;
  position: relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}

.tc-failure-checkmark-circle {
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  stroke-width: 3;
  stroke-miterlimit: 10;
  stroke: var(--bs-failure);
  fill: #fff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.tc-failure-checkmark .tc-failure-left-check {
  stroke-width: 4;
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.tc-failure-checkmark .tc-failure-right-check {
  stroke-width: 4;
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

/* SYNC CSS */
.tc-sync-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.tc-sync-top-content {
  display: flex;
  flex-direction: column;
}

.tc-sync-top-content h5 {
  padding: 0px 30px;
  text-align: center !important;
  font-size: 24px;
  font-weight: 500;
  color: var(--bs-black);
}

.tc-sync-top-content span {
  font-size: 26px;
  font-weight: 700;
  color: var(--bs-primary);
}

.tc-sync-center-content {
  border: 1px solid #dfdfdf;
}

.tc-sync-bottom-content span {
  font-size: 20px;
  font-weight: 600;
  color: var(--bs-primary);
}

.tc-sync-data-status {
  min-height: 180px;
  border: 3px solid var(--bs-primary);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tc-sync-data-status-content svg {
  width: 100px;
  height: 100px;
}

.tc-sync-data-status-content .color-red {
  font-size: 20px;
  font-weight: 600;
  color: var(--bs-red) !important;
}

.tc-sync-data-status-content .color-green {
  font-size: 20px;
  font-weight: 600;
  color: var(--bs-black) !important;
}

/* view large image modal */
.tc-approval-img-modal-bg {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  transform: scale(0.75);
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  background: #00000035;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}

.tc-approval-img-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 999999;
  transform: translate(-50%, -50%) scale(0.75);
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.tc-approval-img-modal img {
  max-width: 100%;
  min-width: 250px;
  height: auto;
}

.tc-approval-img-modal-bg.tc-approval-img-modal-open {
  transform: scale(1);
  pointer-events: all;
  opacity: 1;
}

.tc-approval-img-modal.tc-approval-img-modal-open {
  transform: translate(-50%, -50%) scale(1);
  pointer-events: all;
  opacity: 1;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px var(--bs-success);
  }
}

@keyframes fill2 {
  100% {
    box-shadow: inset 0px 0px 0px 30px var(--bs-failure);
  }
}

.cm_modal_xl .modal-content {
  min-height: calc(100vh - var(--tc-navbar) - var(--tc-footer)) !important;
}

.cm_modal_xl .modal-dialog {
  max-width: 90vw;
  margin-right: auto;
  margin-left: auto;
}

/* Loader */
.tc-custom-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  z-index: 99999999;
}

.tc-custom-loader span {
  font-size: 20px;
  font-weight: 700;
  color: var(--bs-primary);
}

.tc-custom-loader svg {
  width: 115px;
  height: 115px;
}

.tc-custom-loader .loader {
  width: 50px;
  height: 50px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-color: var(--bs-red) #0000;
  -webkit-animation: l16 1s infinite linear;
  animation: l16 1s infinite linear;
  animation-duration: 0.6s;
}

.tc-custom-loader .loader::before {
  content: "";
  grid-area: 1/1;
  margin: 3px;
  border: inherit;
  border-radius: 50%;
}

.tc-custom-loader .loader::before {
  border-color: var(--bs-primary) #0000;
  -webkit-animation: inherit;
  animation: inherit;
  animation-duration: 0.3s;
  animation-direction: reverse;
}

.tc-custom-loader .loader::after {
  margin: 8px;
}

@-webkit-keyframes l16 {
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes l16 {
  100% {
    transform: rotate(1turn);
  }
}

/* Rotate CSS */
.rotate-phone-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  display: none;
}

.rotate-phone-content-show {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999999999;
  background-color: var(--bs-white);
}

.rotate-phone-content img {
  width: 200px;
  height: auto;
}

/* Notifications CSS */
.tc-notification-type-container {
  border-bottom: 1px solid var(--bs-gray-300);
  background: var(--bs-white);
  margin: 0 auto;
  padding: 0px 0px 15px;
  /* min-width: 300px; */
  /* width: 600px; */
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.tabs-contain-mainbox .nav-pills .nav-item .nav-link {
  background: transparent;
  border: 0;
  padding: 0;
}

.tabs-contain-mainbox
  .nav-pills
  .nav-link.active
  .tc-notification-left-content
  .tc-notification-date {
  color: var(--bs-primary);
}

.tc-notification-type-container .tc-notification-content {
  display: flex;
  align-items: center;
  position: relative;
}

.tc-n-notification {
  position: absolute;
  right: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bs-white);
  background: var(--bs-primary);
  line-height: 18px;
  top: 50%;
  transform: translate(0, -50%);
}

.tc-notification-type-container .tc-notification-content svg {
  width: 50px;
  height: 50px;
}

.tc-notification-user-img svg {
  text-align: center;
}

.tc-notification-type-container
  .tc-notification-content
  .tc-notification-left-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 15px;
  width: 100%;
}

.tc-notification-type-container
  .tc-notification-content
  .tc-notification-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tc-notification-type-container
  .tc-notification-content
  .tc-notification-title
  h4 {
  font-size: 20px;
}

.tc-notification-left-content .tc-notification-date {
  font-weight: 600;
  text-align: end;
  margin-bottom: 4px;
  color: var(--bs-gray-300);
}

.tc-view-notification-type {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--bs-gray-300);
}

.tc-view-notification-type svg {
  width: 50px;
  height: 50px;
}

.tc-notification-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 25px;
  padding-bottom: 40px;
}

.right-side-tabs .tab-content {
  height: 75vh;
  overflow: unset;
}

.right-side-tabs .infinite-scroll-component__outerdiv {
  height: 100%;
  overflow: auto;
  padding-right: 10px;
}

.left-side-tabs h2 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 35px;
}

.tc-notification-container .tc-notification-outer-content {
  position: relative;
  width: 100%;
}

.tc-notification-container .tc-notification-content {
  display: flex;
  flex-direction: column;
  background: var(--bs-white);
  padding: 12px;
  border-radius: 10px;
  border: 3px solid var(--bs-primary);
  font-size: 15px;
  width: 100%;
  color: var(--bs-black);
  position: relative;
}

.tc-notification-container .tc-notification-content .tc-notification-date {
  font-weight: 600;
  text-align: end;
  margin-bottom: 4px;
  font-size: 12px;
}

.tc-notification-content .tc_notification_desc {
  font-weight: 700;
  text-transform: uppercase;
}

.tc-notification-container .unread-notifications {
  font-size: 34px;
  color: #115b48;
  position: absolute;
  top: 0;
  left: 14px;
}

/* Browser Compatibility CSS */
.tc-browser-compatibility-wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.tc-browser-compatibility-content {
  min-height: 100px;
  padding: 20px 12px;
  max-width: 600px;
  background-color: var(--bs-white);
}

.tc-browser-compatibility-content .tc-browser-compatibility-logo img {
  width: 80%;
  display: block;
  margin: auto !important;
}

.tc-browser-compatibility-content .tc-browser-compatibility-logo2 img {
  width: 50% !important;
  display: block;
  margin: auto !important;
}

.tc-browser-compatibility-content .card-title {
  color: var(--bs-red);
  font-weight: 700;
  font-size: 1.4rem;
}

.tc-browser-compatibility-content .card-text {
  color: var(--bs-primary);
  font-weight: 700;
  font-size: 1.5rem;
}

/* Add MD */
/* .tc-add-md-page .tc-card-itinerary-container .tc-card-input-container .tc-card-input-label {
  min-width: 190px;
  justify-content: space-between;
}

.tc-add-md-page .tc-card-itinerary-container .tc-card-input-container {
  gap: 40px;
  align-items: baseline;
}

.tc-add-md-page .tc-card-itinerary-container .tc-card-input-container .tc-card-static-input {
  width: 100%;
  border: 2px solid var(--bs-primary);
  border-radius: 4px;
  color: var(--bs-black);
  font-weight: 600;
  padding: 6px 8px;
  font-size: 16px;
}

.tc-add-md-page .tc-card-itinerary-container .tc-card-input-container .color-gray {
  color: var(--bs-white);
  background-color: #a6a6a6;
}

.tc-add-md-page .tc-card-itinerary-container {
  border: 0;
  max-width: 100%;
  width: 100%;
  padding: 0;
} */
.tc-add-md-page.tc-receive-pro-matr-body-section {
  height: 100%;
}

/* .tc-add-md-page .tc-edit-itinerary-wrap {
  height: 52vh;
} */

.tc-add-md-page .tc-custom-select-2 .select__control {
  border: 2px solid var(--bs-primary);
  background: #fff;
  color: #000000;
}

.tc-add-md-page .tc-custom-select-2.error-active {
  border: 0 !important;
}

.tc-add-md-page .tc-custom-select-2.error-active .select__control {
  border: 2px solid red !important;
  background: #fff;
  color: #000000;
}

.tc-add-md-page .tc-custom-select-2 .select__single-value,
.tc-add-md-page .tc-custom-select-2 .select__control .select__placeholder {
  color: var(--bs-black);
  font-weight: 600;
}

.tc-add-md-page
  .tc-custom-select-2
  .select__control
  .select__input-container
  .select__input {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: var(--bs-black) !important;
  border: 0 !important;
}

.tc-add-md-page
  .tc-custom-select-2
  .select__control
  .select__indicators
  .select__dropdown-indicator:before {
  border-top: 12px solid var(--bs-primary);
}

.tc-add-md-page .add-btn {
  position: absolute;
  left: 0;
  bottom: -20px;
  font-size: 12px;
  text-decoration: underline;
  color: #32b0f0;
  font-weight: 500;
  cursor: pointer;
}

h3.modal-text {
  padding-bottom: 20px;
  font-size: 18px;
}

h3.modal-text.modal-add-institution {
  padding: 10px;
  border: 2px solid var(--bs-primary);
  border-radius: 10px;
  margin-bottom: 40px;
  margin-top: 20px;
}

h3.modal-text.modal-submit {
  text-align: center;
}

.tc-add-clinic-modal .tc_add_clinic_input {
  border-radius: 6px;
  width: 80%;
  border: 2px solid #115b48;
  background-color: transparent;
}

.max-w-150 {
  max-width: 150px;
}

.tc-update-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.tc-update-list .btn {
  margin-bottom: 16px;
}

.tc-update-list div:nth-child(odd) {
  border-right: 1px solid #ccc;
}

.tc-update-list div {
  padding: 0 40px;
}

.tc-report-contents {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* overflow: hidden; */
}

h2.tc-report-label {
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.tc-report-list {
  max-width: 100%;
  border-right: 1px solid #ccc;
  padding: 0 25px;
  height: 100%;
}

.tc-report-container:last-child .tc-report-list {
  border-right: 0px;
}

.tc-report-list button:first-child {
  margin-top: 20px;
}

.inner-scroll {
  max-height: 66px;
  overflow: auto;
}

.tc-quicksign-content {
  overflow: hidden;
}

.add-scroll {
  height: 100%;
  overflow: auto;
  padding-bottom: 90px;
  padding-right: 2px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  background: #ffffff;
}

div#tc-product-sampling-tabpane-physicianSamples {
  height: 100%;
}

.popups-btn {
  display: flex;
  gap: 30px;
}

.tc-chart-content .tc-data-table,
.tc-chart-content .tc-table-wrapper,
.tc-chart-content .tc-chart-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table {
  font-size: 11px;
}

.tc-report-page .add-scroll {
  padding-bottom: 0;
}

.tc-report-page .tc-content-wrapper {
  height: 100%;
}

.tc-report-list .btn {
  --bs-btn-font-size: 12px;
  --bs-btn-line-height: 22px;
}

.tc-table-wrapper.poll-call-table {
  padding: 0px 10px;
}

.table-responsive {
  height: 100%;
}

.tc-md-request-history .table-responsive {
  height: 62vh;
  overflow: auto;
}

.tc-height-340 {
  height: 340px;
}

.tc-height-300 {
  height: 300px;
}

.tc-height-280 {
  height: 280px;
}

.tc-height-345 {
  height: 345px;
}

.border-radius-12 {
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.tc-tab-view-table .table thead > tr th {
  padding-right: 2px;
  padding-left: 2px;
}

.tc-tab-view-table .table > :not(caption) > * > * {
  padding: 20px 2px;
}

.tc-tab-view-table .tc-dashboard-table.tc-table-wrapper {
  width: 100%;
  display: block;
}

.tc-tab-view-table .table-responsive {
  height: 100%;
  overflow: unset;
}

.tc-table-wrapper.my-2.calender {
  background: #ffff;
  border: 1px solid var(--bs-gray-100);
  border-radius: 0;
  padding: 0;
}

.calender .table > :not(caption) > * > *,
.calender .table thead > tr {
  background: #ffff;
}

.calender .table tbody > tr:first-child td,
.calender .table > :not(caption) > * > * {
  padding: 5px 20px 62px 5px;
  border-bottom: 1px solid var(--bs-gray-100);
  text-align: left;
  font-size: 12px;
}

.celander-table.table > :not(caption) > * > * {
  padding: 21px 12px !important;
}

/* table.tc-table.dm-class.table> :not(caption)>*>* {
  padding: 21px 12px;
} */
.margin-top-50 {
  margin-top: 42px;
}

.dm-class.table > :not(caption) > * > * {
  padding: 26px 18px;
}

/* .report-table {
  min-height: 100%;
} */

.calender .table tbody::before {
  line-height: 0;
}

.calender .table thead > tr th {
  padding: 6px !important;
  font-size: 16px;
  text-align: center;
  border-right: 1px solid var(--bs-gray-100);
}

.tabs-contain-mainbox .row > div > div,
.tabs-contain-mainbox .row,
.tabs-contain-mainbox {
  height: 100%;
}

.left-side-tabs,
.right-side-tabs {
  padding: 20px;
  background: var(--bs-white);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.dm-birthday-class {
  height: 50vh !important;
  overflow: auto;
}

/* MD Requests History Table */
.tc-md-request-history .tc-table-wrapper.tc-white-table-wrapper {
  background: #fff;
}

.tc-md-request-history .tc-table-wrapper {
  background-color: var(--bs-gray-300);
  padding: 18px 22px 22px;
  border-radius: var(--radius);
  -webkit-border-radius: var(--radius);
  -moz-border-radius: var(--radius);
  -ms-border-radius: var(--radius);
  -o-border-radius: var(--radius);
}

.tc-md-request-history .tc-class-table.tc-table-wrapper {
  background-color: transparent;
}

.tc-md-request-history .tc-class-table.tc-table-wrapper.middle-table {
  padding: 0px 0px 12px;
}

.tc-md-request-history .tc-class-table.tc-table-wrapper.middle-table th {
  font-size: 24px;
}

.tc-md-request-history .tc-class-table.tc-table-wrapper.middle-table td {
  padding: 12px 10px;
}

.tc-md-request-history .main-tab-table-box table.table thead tr th {
  padding: 10px 48px;
}

.tc-md-request-history .tc-class-table.tc-table-wrapper.middle-table td {
  padding: 12px 0;
}

.tc-md-request-history .tc-table-wrapper.tc-white-table-wrapper {
  background: #fff;
}

.tc-md-request-history
  .tc-table-wrapper.tc-white-table-wrapper
  .table
  > :not(caption)
  > *
  > * {
  font-size: 14px;
  border-bottom-width: 0;
  padding: 12px 18px;
  background: transparent;
}

.tc-md-request-history
  .tc-table-wrapper.tc-white-table-wrapper
  .table
  tbody
  > tr
  td:not(:last-child),
.tc-md-request-history
  .tc-table-wrapper.tc-white-table-wrapper
  .table
  tfoot
  > tr
  td:not(:last-child) {
  border-right: 1px solid #f3f3f3;
  border-right: 1px solid var(--bs-gray-100);
}

.tc_oof_request_history .react-datepicker-wrapper input {
  width: 100%;
  padding: 2px;
  font-size: 16px;
  font-weight: 400;
  color: var(--bs-black);
  padding: 6px 8px;
  border: 2px solid var(--bs-red);
  border-radius: 4px;
}
.tc_oof_request_history .date-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  fill: var(--bs-red);
}
.tc_oof_request_history .tc-custom-date-picker-input {
  background: unset;
}

.custom-datepicker-box .react-datepicker-wrapper {
  height: 0;
}

.custom-datepicker-box .tc-report-header-inner-box {
  display: flex;
  flex-direction: column;
}

.custom-datepicker-box .react-datepicker__triangle {
  top: 1px !important;
  right: 50% !important;
  left: unset !important;
  z-index: 9;
  transform: translate(-50%) !important;
}

.custom-datepicker-box p.tc-report-header-date {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.custom-datepicker-box .tc-report-header .tc-report-header-inner-box {
  display: inline-flex;
  flex-direction: column;
}

/* .custom-datepicker-box .react-datepicker-popper {
    inset: -18px auto auto 0px !important;

  } */

.custom-datepicker-box p.tc-report-header-date span {
  white-space: nowrap;
}

.custom-datepicker-box .react-datepicker__navigation {
  top: 9px !important;
}

/* PREVIEW IMAGE MODAL */
.tc-img-preview-modal-bg {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  transform: scale(0.75);
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  background: #00000035;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}

.tc-img-preview-modal-bg.tc-img-preview-modal-open {
  transform: scale(1);
  pointer-events: all;
  opacity: 1;
}

.tc-img-preview-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 999999;
  transform: translate(-50%, -50%) scale(0.75);
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.tc-img-preview-modal img {
  max-width: 100%;
  min-width: 250px;
  height: auto;
}

.tc-img-preview-modal.tc-img-preview-modal-open {
  transform: translate(-50%, -50%) scale(1);
  pointer-events: all;
  opacity: 1;
}

.tc-calender-search-input-row .tc-search-input-content {
  max-width: 147px;
  width: 100%;
  margin-left: auto;
  position: relative;
}

.tc-calender-search-input-row .tc-search-input-content input {
  width: 100%;
  background: var(--bs-gray-300);
  border: none;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 40px 5px 11px;
  color: var(--bs-black);
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-progress-appearance: none;
  border-radius: var(--radius);
  -webkit-border-radius: var(--radius);
  -moz-border-radius: var(--radius);
  -ms-border-radius: var(--radius);
  -o-border-radius: var(--radius);
}

.tc-calender-search-input-row .tc-search-input-content .tc-search-input-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tc-md-list-behavior-input-box.add-note-signature
  .tc-join-call-digi-signaturepad {
  width: 100%;
  height: 150px;
  background-color: var(--bs-white);
  border-radius: var(--radius);
  -webkit-border-radius: var(--radius);
  -moz-border-radius: var(--radius);
  -ms-border-radius: var(--radius);
  -o-border-radius: var(--radius);
}

.sw_popup_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.sw_popup_content {
  background-color: black;
  color: white;
  padding: 35px 50px;
  border-radius: 30px;
  text-align: center;
}

.sw_popup_content p {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.8rem;
}

.sw_popup_content button {
  margin-top: 20px;
  background-color: white;
  color: black;
  text-transform: capitalize;
  border: none;
}

.sw_popup_content button:hover,
.sw_popup_content button:active,
.sw_popup_content button:disabled,
.sw_popup_content button:focus-visible {
  border: none !important;
  color: black !important;
  background-color: white !important;
  opacity: unset;
}

@media (max-width: 1025px) {
  .tc-call-activity-list .tc-call-activity-list-row h5 {
    min-width: 140px;
    /* max-width: 140px; */
  }

  /* .btn {
    --bs-btn-padding-x: 12px;
    --bs-btn-padding-y: 12px;
    --bs-btn-font-size: 16px;
  } */

  h2.tc-report-label {
    font-size: 22px;
  }

  div#Box-3,
  div#Box-1 {
    max-width: 50%;
  }

  /* div#Box-3 .tc-chart-container,
  div#Box-1 .tc-chart-container {
    max-width: 350px;
    margin-bottom: 50px;
  } */

  div#Box-3 .tc-chart-container {
    margin-right: auto;
  }

  /* div#Box-1 .tc-chart-container {
    margin-left: auto;
  } */

  div#Box-2 {
    padding: 0;
  }

  .tc-class-table .table td .tc-class-border,
  .tc-p-0 {
    padding: 0 !important;
  }

  /* 

  div#Box-1 {
    order: 1;
  }

  div#Box-3 {
    order: 2;
  }

  div#Box-2 {
    order: 3;
  } */
  /* h2,
  .h2 {
    font-size: 20px;
  } */

  /* .tc-data-table,
  .tc-table-wrapper,
  .tc-chart-content .tc-chart-container {
    padding: 7px;
  } */
}

.tc-md-list {
  max-width: 500px;
  margin: auto;
}

/* 
.tc-call-activities .tc-table-wrapper {
  padding: 0px 10px;
} */

@media screen and (max-width: 1023px) {
  .tc-footer {
    max-width: calc(100% - 60px);
  }
}

@media screen and (min-width: 992px) {
  .tc-product-pdf-modal .modal-dialog {
    max-width: 950px;
  }
}

@media screen and (max-width: 1024px) {
  /* .tc-update-list div:nth-child(odd) {
    border-right: 0;
  } */

  /* .tc-update-list,
  .tc-report-contents {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  } */

  /* h2.tc-report-label {
    padding-bottom: 0;
    border-bottom: 0px;
  } */

  /* .tc-report-list {
    max-width: 500px;
    border-right: 0px;
    padding: 0;
    margin: auto;
  } */

  .tc-report-list button:first-child {
    margin-top: 0;
  }

  .tc-navbar-title {
    font-size: 18px;
  }

  .tc-chart-content {
    gap: 30px;
  }

  .tc-dashboard-chart {
    width: 270px !important;
    max-width: 100%;
  }

  .tc-product-pdf-modal .tc-product-modal-img {
    max-height: 65vh;
  }

  .responsive-middle {
    padding: 0;
  }
}

@media screen and (max-width: 900px) {
  .tc-audit-report-container .tc-card-input-container {
    display: flex;
    margin-bottom: 10px;
  }

  .tc-audit-report-container .tc-card-input-label {
    flex-direction: column;
    align-items: start;
    margin-bottom: 5px;
  }

  .tc-audit-report-container .tc-custom-select-2 {
    width: 100%;
  }

  .tc-product-pdf-modal .tc-product-modal-img {
    max-height: 50vh;
  }

  .tc-product-pdf-modal .tc-sub-product-img img {
    height: 150px;
    width: 150px;
  }
}

@media screen and (max-width: 767px) {
  .tc-footer {
    padding: 22px 22px;
  }

  .tc-dashboard-chart {
    width: 220px !important;
  }

  .tc-class-table .table tfoot td .tc-class-border::after,
  .tc-class-table .table tfoot td .tc-class-border::before {
    width: max(15%, 5px);
  }
}

@media (max-width: 650px) {
  .tc-product-pdf-modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  .tc-product-pdf-modal .tc-product-modal-img {
    max-height: 50vh;
  }
}

.app-container {
  transition: transform 0.3s ease-in-out;
}

.app-container.keyboard-open {
  transform: translateY(-50px);
  /* Adjust this value to your needs */
}
